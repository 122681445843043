import styles from "./EchoHello.module.css"
import { useEffect, useState } from "react";
import { ProfileService } from "../../../services/ProfileService";
import { ProfileSettingService } from "../../../services/ProfileSettingService";
import { useSelector } from "react-redux";
import { useWebSocket } from './../../../hooks/UseWebSocket';
import SettingsSubMenu from "../../ui/organisms/settingsSubMenu/SettingsSubMenu";
import EchoHelloSettings from "../../ui/organisms/echoHelloSettings/EchoHelloSettings";
import MainMenu from "../../ui/molecules/mainMenu/MainMenu";
import EchoHelloLicenses from "../../ui/organisms/echoHelloLicenses/EchoHelloLicenses";
import { EchoHelloLicenceService } from '../../../services/EchoHelloLicenceService';
import { EchoHelloProfileService } from "../../../services/EchoHelloProfileService";


const EchoHello = () => {
    const company = useSelector((state) => state.companyWithLocationsAndDisplays.value)
    const [isCompanySidebarOpen, setIsCompanySidebarOpen] = useState(false)
    const user = useSelector((state) => state.user.value);
    const [activePage, setActivePage] = useState("profiles");
    const [licenceData, setLicenceData] = useState([]);
    const selectedCompany = useSelector((state) => state.selectedCompany.value);
    const [profiles, setProfiles] = useState([{ "id": 2, "name": "Profile 1" }])
    const companyId = selectedCompany ? selectedCompany.Id : company.Id;
    const [selectedProfile, setSelectedProfile] = useState();


    useEffect(() => {
      refreshLicenceList()
      }, [company, profiles])

      useEffect(() => {
        EchoHelloProfileService.getAllProfiles(companyId).then((result) => {
            setProfiles(result);
            setSelectedProfile(result[0]);
        })
    }, [selectedCompany])

    const refreshLicenceList = async () => {
       const response = await EchoHelloLicenceService.getAllLicences(companyId)
        if (response === "noKey") {
          setLicenceData([])
          return [];
        } else {
          setLicenceData(response);
          return response;
        }
    }

    return (
        <div className={styles.wrapper}>
        <MainMenu />
                {activePage === "profiles" &&
                  <EchoHelloSettings licenceData={licenceData} setActivePage={setActivePage} setIsCompanySidebarOpen={setIsCompanySidebarOpen} profiles={profiles} setProfiles={setProfiles} selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile} refreshLicenceList={refreshLicenceList} />
                }
                {activePage === "licenses" &&
                  <EchoHelloLicenses licenceData={licenceData} setLicenceData={setLicenceData} setActivePage={setActivePage} setIsCompanySidebarOpen={setIsCompanySidebarOpen} profiles={profiles} refreshLicenceList={refreshLicenceList} />
                }
            {isCompanySidebarOpen &&
                <div>
                    <SettingsSubMenu canAccessUsers={false} className={styles.settingSubMenu} user={user} />
                    <div className={styles.overlay} onClick={() => setIsCompanySidebarOpen(false)} />
                </div>
            }
        </div>
    )
}

export default EchoHello;