import { useEffect, useRef, useState } from 'react';
import TableHeader from '../../molecules/TableHeader/TableHeader';
import AccountManagementTableRow from '../../molecules/TableRow/TableRow';
import styles from './Table.module.css';

const Table = ({ data, autoTable, currentUser, multiUsers, setMultiUsers, headerColumns, rowColumns, checkboxColumn, actionColumn, multiSelectIdColumn, activeFilters, setActiveModal, setGiveAccessUser, contextMenuType, icons, extraSelectColumn, refresh }) => {
  const tableRef = useRef();
  const [minWidth, setMinWidth] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const rect = tableRef.current.getBoundingClientRect();
      if (rect.width < 774) {
        setMinWidth(true)
      }
    }
    window.addEventListener("load", handleResize)
    window.addEventListener("resize", handleResize);
    return () => { window.removeEventListener("resize", handleResize); window.removeEventListener("load", handleResize) };
  }, [])

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  const findLongestString = (columnName) => {
    let stringLength = 1;
    data.forEach(item => {
      const itemValue = getNestedValue(item, columnName);
      if (itemValue != null) {
        return stringLength = itemValue.length > stringLength ? itemValue.length : stringLength
      }
    });
    return `${stringLength * 12}px`
  }
  const setGridTemplate = (columns) => {
    let template = ""
    
    columns.forEach((item, index) => {
      if (checkboxColumn !== undefined && index === checkboxColumn) {
        template = `${template} 48px `
      }
      template = item.fixedWidth
        ? `${template} ${item.fixedWidth}`
        : item.maxContent ? `${template} minmax(${findLongestString(item.columnName)}, auto)`
          : `${template} minmax(${item.minWidth ? item.minWidth : "auto"}, ${item.maxWidth ? item.maxWidth : "auto"})`
      if (actionColumn !== undefined && index === actionColumn) {
        template = `${template} 120px `
      }
    })
    return { gridTemplateColumns: template }
  }

  return (
    <div ref={tableRef} className={[styles.table, autoTable && styles.auto, minWidth && styles.minWidth].filter(e => !!e).join(" ")}>
      <TableHeader
        gridStyle={setGridTemplate(headerColumns)}
        className={styles.tableHeader}
        data={data}
        headerData={headerColumns}
        actionColumn={actionColumn}
        checkboxColumn={checkboxColumn}
        multiSelectIdColumn={multiSelectIdColumn}
        extraSelectColumn={extraSelectColumn}
      />
      {data.map((dataItem, index) => {
        return <AccountManagementTableRow
          gridStyle={setGridTemplate(rowColumns)}
          className={styles.tableRow}
          key={index}
          multiUsers={multiUsers}
          dataItem={dataItem}
          currentUser={currentUser}
          setActiveModal={setActiveModal}
          contextMenuType={contextMenuType}
          rowColumns={rowColumns}
          icons={icons}
          multiSelectIdColumn={multiSelectIdColumn}
          actionColumn={actionColumn}
          checkboxColumn={checkboxColumn}
          extraSelectColumn={extraSelectColumn}
          refresh={refresh}
        />
      })}
    </div>
  )
}

export default Table;