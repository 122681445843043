import styles from "./EchoHelloLicenses.module.css"
import { TitleBanner, MenuContainer, MenuItem, ProfilePicker, BackgroundType } from "@ctouch-europe-b-v/myctouch-component-library"
// import EchoSettingGroup from "../echoSettingGroup/EchoSettingGroup";
import { useEffect, useState, useRef, useContext } from "react";
import { MultiSelectContext, MultiSelectDispatchContext } from '../../../../contexts/MultiSelectContext';
// import AddProfileModal from "../addProfileModal/AddProfileModal";
import ProfileMenuItem from "../profileMenuItem/ProfileMenuItem";
import { useSelector } from "react-redux";
import { useWebSocket } from '../../../../hooks/UseWebSocket';
import useUpdateUser from '../../../../hooks/useUpdateUser';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import ModalAddLicence from "../modalAddLicence/ModalAddLicence";
import Table from '../Table/Table';
import { ButtonType, ItemTitle, ItemText, Button, Filter, MenuItemTitle, MenuItemDivider, MenuItemSimple, MenuItemAction, Snackbar } from "@ctouch-europe-b-v/myctouch-component-library";
import ModalAssignProfile from "../modalAssignProfile/ModalAssignProfile";
import { EchoHelloLicenceService } from "../../../../services/EchoHelloLicenceService";

const tempData = [
  {
    LicenceKey: "EHQY-P78J-MD40-GB5G",
    DeviceName: "Room-Francois",
    ExpirationDate: "2024-03-16T00:00:00",
    Profile: { ProfileName: "Default" }
  },
  {
    LicenceKey: "EHAC-P61L-MC31-GB3B",
    DeviceName: "Super Long long long long long long longRidiculous Room Name",
    ExpirationDate: null,
    Profile: { ProfileName: "Profile Three" }
  },
  {
    LicenceKey: "EH8F-P94C-MP24-GB9L",
    DeviceName: null,
    ExpirationDate: "2025-05-23T00:00:00",
    Profile: { ProfileName: "Profile Three" }
  },

]

const EchoHelloLicenses = ({ setActivePage, setIsCompanySidebarOpen, licenceData, setLicenceData, profiles, refreshLicenceList }) => {
  const filterRef = useRef();
  const [selectedProfile, setSelectedProfile] = useState()
  const [settings, setSettings] = useState([])
  const { joinRoom, send } = useWebSocket()
  const company = useSelector((state) => state.companyWithLocationsAndDisplays.value)
  const [displays, setDisplays] = useState([])
  const selectedCompany = useSelector((state) => state.selectedCompany.value);
  const [activeFiltersAmount, setActiveFiltersAmount] = useState(null);
  const [filterMenu, openFilterMenu] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [filterList, setFilterList] = useState();
  const [multiSelectActive, setMultiSelectActive] = useState(false);
  const [multiUsers, setMultiUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [licenceModal, setLicenceModal] = useState("");
  const user = useSelector((state) => state.user.value);
  const [assignProfileModal, setAssignProfileModal] = useState(false)
  const multiSelected = useContext(MultiSelectContext);
  const multiSelectDispatch = useContext(MultiSelectDispatchContext);
  const updateUserHandler = useUpdateUser();
  const isReseller = user?.Roles?.some(item => item?.Name === "Reseller");
  const isAdmin = user?.Roles?.some(item => item?.Name === "Administrator");
  const isCTOUCH = user?.Roles?.some(item => item?.Name === "CTOUCH");
  const companyId = selectedCompany ? selectedCompany.Id : company.Id;
  const [warning, setWarning] = useState("")
  const selectedUser = useSelector(state => state.selectedUser.value)

  const tableHeaderColumns = [{centered: false, text: "License Key", maxWidth: "296px"}, {
    centered: false,
    text: "Device Name",
    maxWidth: "296px"
}, {centered: false, text: "Expires On", maxWidth: "200px"}, {
    centered: false,
    text: "Profile",
    maxContent: true,
    columnName: "Profile.ProfileName"
}];

const tableRowColumns = [{columnName: "LicenceKey", type: "Text", maxWidth: "296px"},
    {columnName: "DeviceName", type: "Text", maxWidth: "296px"},
    {columnName: "ExpirationDate", type: "Date", centered: false, maxWidth: "200px"},
    {columnName: "Profile.ProfileName", type: "Text", centered: false, maxContent: true},];


  useOnClickOutside(filterRef, () => openFilterMenu(false))

  useEffect(() => {
    getFilterList();
  }, [licenceData])

  useEffect(() => {
    if (filterMenu) {
      const rect = filterRef.current.getBoundingClientRect();
      filterRef.current.style.maxHeight = `calc(95vh - ${rect.top}px - 64px)`;
      filterRef.current.style.right = `0`;
    }
  }, [filterMenu])

  // Remove all users from multi select when another organization is chosen
  useEffect(() => {
    if (multiSelectDispatch) {
      multiSelectDispatch({ type: 'Remove All' });
    } else {
      console.error('multiSelectDispatch is null');
    }
  }, [selectedCompany])

  useEffect(() => {
    setMultiSelectActive(multiSelected.length > 0);
  }, [multiSelected])

  useEffect(() => {
    const filterBoolChecker = (trueVal, falseVal, object, key, filters) => {
      return (filters.includes(trueVal) && object[key] === 1) || (filters.includes(falseVal) && object[key] === 0)
    }
    setFilteredData(() => {
      let newList = JSON.parse(JSON.stringify(licenceData));
      Object.entries(activeFilters).forEach(([filterField, filters]) => {
        if (filters.length !== 0) {
          newList = newList.filter((user) => {
            return filters.includes(getNestedValue(user, filterField))
          })
        }
      })
      return newList;
    })
  }, [activeFiltersAmount, licenceData])

  const convertBoolVal = (val, newVal) => {
    return typeof val === "number" && val === 1 ? newVal.trueVal : typeof val === "number" && val === 0 ? newVal.falseVal : val === null ? newVal.falseVal : val
  }

  const getFilterButtonText = () => {
    return `Filters${activeFiltersAmount > 0 ? ` [${activeFiltersAmount}]` : ''}`
  }
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
  const getFilterList = () => {
    const filterableColumn = ["ProfileName"];
    const getFilterFields = (data, fieldName = "", filterFields = [], index = 0) => {
      Object.entries(data).forEach(([key, value]) => {
        if ((value !== null && value !== undefined) && typeof value === "object" && !Array.isArray(value)) {
          return getFilterFields(value, fieldName === "" ? key : `${fieldName}.${key}`, filterFields, index + 1)
        }
        else if (filterableColumn.includes(key)) {
          fieldName = fieldName === "" ? key : `${fieldName}.${key}`;
          filterFields.push(fieldName);
          fieldName = fieldName.includes(".") ? fieldName : "";
        }
      })
      return filterFields
    }
    let filterFields = [];
    let tempFilterList = {};
    licenceData?.forEach(user => {
      filterFields = getFilterFields(user)
      filterFields.map((field) => {
        let newField = field === "SphereAccess" ? convertBoolVal(user[field], { trueVal: "Yes", falseVal: "No" }) : getNestedValue(user, field) === null ? "Undefined" : getNestedValue(user, field)
        const newItem = newField;
        if (tempFilterList[field] === undefined) {
          tempFilterList[field] = []
          tempFilterList[field].push(newItem);
        } else if (tempFilterList[field].filter(item => item === newField)?.length === 0) {
          tempFilterList[field].push(newItem);
        }
      })
    });
    setFilterList(tempFilterList)
  }

  const handleActiveFilters = (filterField, val) => {
    const value = Array.isArray(val) ? val[0] : val;
    let tempFilters = activeFilters;
    if (tempFilters[filterField] === undefined) {
      setActiveFiltersAmount(prev => prev = prev + 1)
      tempFilters[filterField] = [value];
    } else if (!tempFilters[filterField].includes(value)) {
      tempFilters[filterField] = [...tempFilters[filterField], value];
      setActiveFiltersAmount(prev => prev = prev + 1)
    } else {
      tempFilters[filterField] = tempFilters[filterField].filter(filter => filter !== value)
      setActiveFiltersAmount(prev => prev = prev - 1)
    }
    setActiveFilters(tempFilters)
  }


  useEffect(() => {
    let items = [];
    company?.Locations.forEach(location => {
      location.Displays.forEach(display => {
        items.push(display);
      });
    });
    setDisplays(items);
  }, [company])

  const sendAllSettings = (profileId) => {
    licenceData.filter((EchoHelloClient) => {
      return EchoHelloClient.ProfileId === profileId
    }).forEach(EchoHelloClient => {
      joinRoom(EchoHelloClient.AssignedTo)
      settings.forEach((setting) => {
        send(EchoHelloClient.AssignedTo, setting.Setting.LauncherSettingName, setting.CurrentValue)
        if (setting.Setting.LauncherSettingName === "logoSource" && setting.CurrentValue === "CTOUCH") {
          send("logoUrl", "https://ctouchwebtools.blob.core.windows.net/echo/ctouchLogo.png")
        }
      })
    });
  }

  const addLicenceHandler = async (newData) => {
    await EchoHelloLicenceService.addLicenceToCompany(newData.licenceCode, companyId, newData.profileId).then((response) => {
      console.log(response);
      if (response == "Bad Request") {
        setWarning({ type: "invalidLicence" })
      }
      if (response.ProfileId === newData.profileId) {
        refreshLicenceList();
        setLicenceModal(false);
      }
    });
  }

  const renderFilterTitle = (key) => {
    // to do, if a key has multiple parts, check if part exists in another title. if so add name second to last part to key title
    const splitKey = key.split(".");
    const keyName = splitKey[splitKey.length - 1];
    return keyName.split(/(?=[A-Z])/).join(" ")
  }

  return (
    <div className={styles.mainContainer}>
      <MenuContainer title="Settings" className={styles.submenu}>
        <MenuItem type="Title" title="Section" />
        <MenuItem type="Action" text="Profiles" iconName="sliders" onClick={() => { setActivePage("profiles") }} />
        <MenuItem type="Action" text="Licenses" isSelected iconName="file-certificate" onClick={() => { setActivePage("licenses") }} />
        {(!isAdmin && !isReseller && !isCTOUCH) || (!isAdmin && (isReseller || isCTOUCH) && selectedCompany === null) || (isAdmin && selectedUser !== null) ?
                <></>
                :
        <>
        <MenuItem type="Divider" />
        <MenuItem type="Title" title="Assign Profiles" />
        {profiles.map((profile) => {
          return <MenuItem type={"Action"} text={profile.ProfileName} isDisabled={!multiSelectActive} iconName={"arrow-right"} onClick={() => setAssignProfileModal(profile)} />
        })}
        </>}
      </MenuContainer>
      {/* todo set correct banner background */}
      <TitleBanner title="CTOUCH Echo Hello" subtitle="Hybrid meetings made easy with the CTOUCH Echo interface." color="White" className={styles.banner}></TitleBanner>
      <div className={styles.content}>
        <ProfilePicker onClick={() => setIsCompanySidebarOpen(true)} backgroundType={BackgroundType.grey} buttonText="Change" className={styles.profilePicker} settingType={"organization"} settingTarget={selectedCompany?.Name ? selectedCompany?.Name : "yourself"} />

        {(!isAdmin && !isReseller && !isCTOUCH) || (!isAdmin && (isReseller || isCTOUCH) && selectedCompany === null) || (isAdmin && selectedUser !== null) ?
            <ItemText className={styles.infoText} text={"The selected user/organisation has no access to this page. Please pick a different one, using the button above."} />
            :
        <>
        <div className={styles.contentHeaderWrapper}>
          <div className={styles.titleContainer}>
            <ItemTitle title={`Licences (${licenceData?.length})`} />
            <ItemText className={styles.infoText} text={"Manage your licenses and assign profiles."} />
          </div>
          <div className={styles.buttonContainer}>
            <Button type={ButtonType.primary} text={"Add License"} onClick={() => { setLicenceModal(true) }} />
            <div className={styles.filterWrapper}>
              <Button type={ButtonType.secondary} text={getFilterButtonText()} onClick={() => openFilterMenu(prev => !prev)} />
              {filterMenu &&
                <Filter ref={filterRef} className={[styles.accountsFilter].filter(e => !!e).join(" ")} onClose={() => openFilterMenu(false)} backgroundType={"Grey"}  >
                  <MenuItem type={"Action"} filterMenu text={"Reset"} iconName={"arrow-rotate-left"} textClassName={[styles.reset, styles.text].filter(e => !!e).join(" ")} iconClassName={[styles.reset, styles.icon].filter(e => !!e).join(" ")} onClick={() => { setActiveFilters({}); setActiveFiltersAmount(0) }} />
                  <MenuItem type={"Divider"} filterMenu />
                  {Object.entries(filterList).map(([key, value], index) => {
                    return <MenuItem key={`${index}${key}`} withCheckbox filterMenu type={"Collection"} text={renderFilterTitle(key)} selected={activeFilters[key]} setSelected={(val) => handleActiveFilters(key, val)} list={value} visualDropdownProp={key} />
                  })}
                </Filter>
              }
            </div>
          </div>
        </div>
        <Table extraSelectColumn={[{ column: "Profile.ProfileName", value: "Profile.ProfileName" }]} multiSelectIdColumn={"LicenceKey"} rowColumns={tableRowColumns} headerColumns={tableHeaderColumns} actionColumn={tableHeaderColumns.length - 1} checkboxColumn={0} setMultiUsers={setMultiUsers} data={filteredData} activeFilters={activeFilters} contextMenuType={"echoHelloLicence"} refresh={refreshLicenceList} />
        </>
        }


      </div>
      {licenceModal && <ModalAddLicence onSave={(e) => addLicenceHandler(e)} onCancel={() => {setLicenceModal(false); setWarning("");}} profiles={profiles} warning={warning} setWarning={setWarning} />}
      {assignProfileModal !== false && <ModalAssignProfile onClose={() => setAssignProfileModal(false)} profile={assignProfileModal} licenceData={licenceData} setLicenceData={setLicenceData} sendSettings={sendAllSettings} />}
    </div>
  )
}

export default EchoHelloLicenses