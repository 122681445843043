import { AxiosService } from './AxiosService';

const baseUrl = "/EchoHelloProfile";
const axiosService = AxiosService(process.env.REACT_APP_API_URL)

export const EchoHelloProfileService = {
    async getAllProfiles(companyId) {
        return await axiosService.getRequest(`${baseUrl}/getAll/${companyId}/`)
    },
    async getProfile(profileId) {
        return await axiosService.getRequest(`${baseUrl}/getProfile/${profileId}`)
    },
    async createProfile(profile) {
        return await axiosService.postRequest(`${baseUrl}`, profile);
    },
    async updateProfile(profile) {
        return await axiosService.putRequest(`${baseUrl}/edit`, profile);
    },
    async deleteProfile(profileId) {
        return await axiosService.deleteRequest(`${baseUrl}/delete/${profileId}`);
    },
    async pasteProfileSettings(copiedEchoHelloProfileId, profileId) {
        return await axiosService.putRequest(`${baseUrl}/copyProfileSettings/${copiedEchoHelloProfileId}/${profileId}`)
    },
}