import axios from "axios";

const createApi = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
  })
}

const errorHandler = (error) => {
  const statusCode = error.response?.status
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}


export const AxiosService = (baseURL) => {

  const api = createApi(baseURL);
  if (baseURL === process.env.REACT_APP_API_URL) {
    api.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${process.env.REACT_APP_MYCTOUCH_JSON_TOKEN_API}`
      return config
    })
  } else if (baseURL === process.env.REACT_APP_SPHERE_API_URL || baseURL === process.env.REACT_APP_SPHERE_API_V2_URL) {
    api.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${process.env.REACT_APP_CLOUD_JSON_TOKEN_API}`
      return config
    })
  }
  api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
  })

  return {
    async getRequest(url, headers) {
      const response = await api.request({
        url: url,
        headers: headers && headers,
        method: "GET",
      });
      return response.data;
    },

    async postRequest(url, data, headers) {
      const response = await api.request({
        url: url,
        headers: headers && headers,
        method: "POST",
        data: data
      });
      return response.data;
    },
    async deleteRequest(url, data, headers) {
      const response = await api.request({
        url: url,
        headers: headers && headers,
        method: "DELETE",
        data: data
      });
      return response.data;
    },
    async putRequest(url, data, headers) {
      const response = await api.request({
        url: url,
        headers: headers && headers,
        method: "PUT",
        data: data
      });
      return response.data;
    }
  }
};