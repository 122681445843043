import styles from './MainMenu.module.css';
import { SideMenuContainer, MenuButton, Logo, MenuButtonType } from '@ctouch-europe-b-v/myctouch-component-library';
import { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Routes from '../../../../enums/Routes';
import Permissions from '../../../../enums/Permissions';

const MainMenu = ({ defaultVersion = false }) => {
  const viewPermissions = Permissions.read;
  const [open, setOpen] = useState("closed");
  const [activeLink, setActiveLink] = useState("")
  const user = useSelector((state) => state.user.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const company = useSelector((state) => state.company.value);
  const selectedCompany = useSelector((state) => state.selectedCompany.value)
  const location = useLocation();
  const { pathname } = location;
  const [activeCompanyPermissions, setActiveCompanyPermissions] = useState(!defaultVersion && user?.Permissions[0]?.Company);
  const [activeUserPermissions, setActiveUserPermissions] = useState(!defaultVersion && user?.Permissions[0]?.User);
  const userTitleHandler = () => {
    if (defaultVersion) {
      return `MyCTOUCH User`;
    }
    if (user.FirstName && user.LastName) {
      return `${user.FirstName} ${user.LastName}`
    }
    return null
  }

  const selectedUserIsNotUser = () => {
    return selectedUser.FirstName !== user.FirstName && selectedUser.LastName !== user.LastName
  }

  const userSubTitleHandler = () => {
    var subTitle = undefined;
    if (selectedCompany) {
      subTitle = `as ${selectedCompany.Name}`
    }
    if (selectedUser && selectedUserIsNotUser()) {

      subTitle = selectedUser.FirstName !== null && selectedUser.FirstName !== "" && selectedUser.LastName !== null && selectedUser.LastName !== "" ? `as ${selectedUser.FirstName} ${selectedUser.LastName}` : `as ${selectedUser.Email}`
    }
    return subTitle
  }

  return (
    <SideMenuContainer className={[styles.container, open === "open" ? styles.open : styles.closed, styles.important].filter(e => !!e).join(" ")} setMenuHovered={(bool) => setOpen(bool ? "open" : "closed")} state={open}>
      <div className={styles.upperContainer}>
        <div className={styles.logoContainer}>
          <Logo icon={true} color="White" title={open === "open"} />
        </div>
        <div className={styles.firstLinksContainer}>
          <NavLink className={[styles.positionHome, styles.positions].filter(e => !!e).join(" ")} to={`${Routes.home}`}>
            {({ isActive, isPending }) =>
              <MenuButton version={isActive && MenuButtonType.active} color="white" className={styles.positionHome} iconName={"house"} title={open === "open" ? "Home" : undefined} />
            }
          </NavLink>
          {(!defaultVersion && activeCompanyPermissions.includes(viewPermissions.origin) && activeUserPermissions.includes(viewPermissions.originView) || defaultVersion) &&
            <NavLink className={[styles.positionOrigin, styles.positions].filter(e => !!e).join(" ")} to={`/${Routes.origin}`}>
              {({ isActive, isPending }) =>
                <MenuButton version={isActive ? MenuButtonType.active : defaultVersion ? "Disabled" : undefined} color="white" iconName={"cloud"} title={open === "open" ? "Origin" : undefined} subTitle={open === "open" ? "Cloud Access" : undefined} />
              }
            </NavLink>
          }
          {(!defaultVersion && activeCompanyPermissions.includes(viewPermissions.echo) && activeUserPermissions.includes(viewPermissions.echoView) || defaultVersion) &&
            <NavLink className={[styles.positionEcho, styles.positions].filter(e => !!e).join(" ")} to={`/${Routes.echo}`}>
              {({ isActive, isPending }) =>
                <MenuButton version={isActive ? MenuButtonType.active : defaultVersion ? "Disabled" : undefined} color="white" iconName={"rocket"} title={open === "open" ? "Echo" : undefined} subTitle={open === "open" ? "Display Interface" : undefined} />
              }
            </NavLink>
          }
          {(!defaultVersion && activeUserPermissions.includes(viewPermissions.echoHelloView) || defaultVersion) &&
            <NavLink className={[styles.positionEcho, styles.positions].filter(e => !!e).join(" ")} to={`/${Routes.echoHello}`}>
              {({ isActive, isPending }) =>
                <MenuButton version={isActive ? MenuButtonType.active : defaultVersion ? "Disabled" : undefined} color="white" iconName={"rocket"} title={open === "open" ? "Echo Hello" : undefined} subTitle={open === "open" ? "Display Interface" : undefined} />
              }
            </NavLink>
          }
        </div>
        <div className={styles.secondLinksContainer}>
          {(!defaultVersion && (user.SphereAccess === 1 && activeCompanyPermissions.includes(viewPermissions.sphere)) || defaultVersion) &&
            <NavLink className={[styles.positionSphere, styles.positions].filter(e => !!e).join(" ")} to="#" onClick={() => window.open(Routes.sphere)}>
              {({ isActive, isPending }) =>
                <MenuButton version={defaultVersion ? "Disabled" : undefined} color="white" iconName={"display"} title={open === "open" ? "Sphere" : undefined} subTitle={open === "open" ? "Device Management" : undefined} />
              }
            </NavLink>
          }
          {(!defaultVersion && user?.Roles?.some(item => item?.Name === "CTOUCH") && user.Permissions[0].User.includes(viewPermissions.SLA) || defaultVersion) &&
            <NavLink className={[styles.positionSLA, styles.positions].filter(e => !!e).join(" ")} to={`/${Routes.sla}`}>
              {({ isActive, isPending }) =>
                <MenuButton version={isActive ? MenuButtonType.active : defaultVersion ? "Disabled" : undefined} color="white" iconName={"file-signature"} title={open === "open" ? "SLA Checker" : undefined} />
              }
            </NavLink>
          }
          {(!defaultVersion && activeUserPermissions.includes(viewPermissions.accountManagement) || defaultVersion) &&
            <NavLink className={[styles.positionAccountMan, styles.positions].filter(e => !!e).join(" ")} to={`/${Routes.accountManagement}`}>
              {({ isActive, isPending }) =>
                <MenuButton version={isActive ? MenuButtonType.active : defaultVersion ? "Disabled" : undefined} color="white" iconName={"users"} title={open === "open" ? "Account Management" : undefined} />
              }
            </NavLink>
          }
          <NavLink className={[styles.positionSupport, styles.positions].filter(e => !!e).join(" ")} to="#" rel={"noopener noreferrer"} target={"_blank"} onClick={() => window.open(Routes.support, "rel=noopener noreferrer")}>
            {({ isActive, isPending }) =>
              <MenuButton color="white" iconName={"headset"} title={open === "open" ? "Support" : undefined} />
            }
          </NavLink>
        </div>
      </div>
      <div className={styles.lowerContainer}>
        {(!defaultVersion && activeCompanyPermissions.includes(viewPermissions.settings) || defaultVersion) &&
          <NavLink className={[styles.positionUser, styles.positions, (selectedUser !== null || selectedCompany !== null) && styles.bold,].filter(e => !!e).join(" ")} to={`/${Routes.settings}`}>
            {({ isActive, isPending }) =>
              <MenuButton className={(isActive && selectedUser === null && selectedCompany === null) && styles.active} version={defaultVersion ? "Disabled" : (selectedUser !== null && selectedUserIsNotUser()) || selectedCompany !== null ? MenuButtonType.bold : isActive ? MenuButtonType.active : MenuButtonType.border} color="white" iconName={"user"} title={open === "open" && userTitleHandler()} subTitle={open === "open" && userSubTitleHandler()} />
            }
          </NavLink>
        }
      </div>
    </SideMenuContainer>
  )
}

export default MainMenu;