import { useState } from "react";
import { useEffect } from "react";
import styles from "./HomePage.module.css"
import TitleSubTitle from "../../ui/atoms/titleSubtitle/TitleSubtitle";
import CardGrid from "../../ui/organisms/cardGrid/CardGrid";
import MainMenu from '../../ui/molecules/mainMenu/MainMenu';
import MarketingContent from "../../ui/organisms/marketingContent/MarketingContent";
import { useSelector } from "react-redux";
import UserNameModal from "../../ui/organisms/userNameModal/UserNameModal";
import SearchBar from "../../ui/organisms/searchBar/SearchBar";
import PageKeywords from "../../../enums/PageKeywords";
import { useNavigate } from "react-router-dom";
import Routes from "../../../enums/Routes";
import { AzureService } from "../../../services/AzureService";
import Permissions from "../../../enums/Permissions";

const HomePage = () => {
    const viewPermissions = Permissions.read;
    const quickAccess = [{
        "title": "Origin",
        "text": "Quickly connect to a display to access your files on-screen.",
        "type": "Input",
        "backgroundType": "#008FDA",
        "buttonText": "Connect",
        "buttonType": "Secondary Outline Light",
        "placeholder": "Session ID",
        "textColor": "White",
        "firstIconName": "cloud",
        "firstIconType": "fas",
        "accessPermission": viewPermissions.origin,
        "viewPermission": viewPermissions.originView,
        "secondIconName": "link-simple-slash",
        "secondIconType": "fas",
        "isInvisibleWhenNoPermission": true
    },
    {
        "title": "Sphere",
        "text": "Register and manage your displays remotely using Sphere.",
        "type": "Button",
        "backgroundType": "Grey",
        "marketingButtonText": "Learn More",
        "buttonText": "Open",
        "buttonType": "Secondary Outline",
        "firstIconName": "display",
        "firstIconType": "fas",
        "accessPermission": viewPermissions.sphere,
        "onClick": () => window.open(Routes.sphere),
        "isInvisibleWhenNoPermission": true
    },
    {
        "title": "Echo Hello",
        "text": "Hybrid meetings made easy with the CTOUCH Echo interface.",
        "type": "Button",
        "backgroundType": "BloodOrange",
        "textColor": "White",
        "buttonText": "Open",
        "marketingButtonText": "Learn More",
        "buttonType": "Secondary Outline Light",
        "firstIconName": "video",
        "firstIconType": "fas",
        "accessPermission": viewPermissions.echoHelloView,
        "marketingOnClick": () => window.open(Routes.echoHello),
        "onClick": () => navigate(Routes.echoHello),
        "isInvisibleWhenNoPermission": true
    },]

    const apps = [
        {
            "title": "Account Management",
            "text": "Manage and approve user accounts for your organization",
            "type": "Button",
            "backgroundType": "Grey",
            "buttonText": "Open",
            "buttonType": "Secondary Outline",
            "firstIconName": "users",
            "firstIconType": "fas",
            "accessPermission": viewPermissions.accountManagement,
            "onClick": () => navigate(Routes.accountManagement),
            "isInvisibleWhenNoPermission": true
        },
        {
            "title": "Echo",
            "text": "The same, personalized experience on every CTOUCH display.",
            "type": "Button",
            "backgroundType": "BloodOrange",
            "textColor": "White",
            "buttonText": "Open",
            "marketingButtonText": "Coming Soon",
            "buttonType": "Secondary Outline Light",
            "firstIconName": "rocket",
            "firstIconType": "fas",
            "accessPermission": viewPermissions.echo,
            "viewPermission": viewPermissions.echoView,
            // "marketingOnClick": () => window.open(Routes.marketingEcho),
            "onClick": () => navigate(Routes.echo),
            "isInvisibleWhenNoPermission": true
        },
        {
            "title": "Echo Hello",
            "text": "Hybrid meetings made easy with the CTOUCH Echo interface.",
            "type": "Button",
            "backgroundType": "BloodOrange",
            "textColor": "White",
            "buttonText": "Open",
            "marketingButtonText": "Learn More",
            "buttonType": "Secondary Outline Light",
            "firstIconName": "video",
            "firstIconType": "fas",
            "accessPermission": viewPermissions.echoHelloView,
            "marketingOnClick": () => window.open(Routes.echoHello),
            "onClick": () => navigate(Routes.echoHello),
            "isInvisibleWhenNoPermission": true
        },
        {
            "title": "Origin",
            "text": "Easily access your personal cloud files on every CTOUCH display.",
            "type": "Button",
            "backgroundType": "PurpleHazeFlipped",
            "buttonText": "Open",
            "textColor": "White",
            "marketingButtonText": "Coming Soon",
            "buttonType": "Secondary Outline Light",
            "firstIconName": "users",
            "firstIconType": "fas",
            "accessPermission": viewPermissions.origin,
            "viewPermission": viewPermissions.originView,
            // "marketingOnClick": () => window.open(Routes.marketingOrigin),
            "onClick": () => navigate(Routes.origin),
            "isInvisibleWhenNoPermission": true

        },
        {
            "title": "SLA Checker",
            "text": "Easily retrieve display data using the serial number.",
            "type": "Button",
            "backgroundType": "Grey",
            "buttonText": "Open",
            "buttonType": "Secondary Outline",
            "firstIconName": "file-signature",
            "firstIconType": "fas",
            "accessPermission": viewPermissions.SLA,
            "onClick": () => navigate(Routes.sla),
            "isInvisibleWhenNoPermission": true
        },
        {
            "title": "Sphere",
            "text": "Register and manage your displays remotely using Sphere.",
            "type": "Button",
            "backgroundType": "Grey",
            "buttonText": "Open",
            "marketingButtonText": "Learn More",
            "buttonType": "Secondary Outline",
            "firstIconName": "display",
            "firstIconType": "fas",
            "accessPermission": viewPermissions.sphere,
            "marketingOnClick": () => window.open(Routes.marketingSphere),
            "onClick": () => window.open(Routes.sphere),
            "isInvisibleWhenNoPermission": true
        },
        {
            "title": "Support",
            "text": "Need help? Our support colleagues are happy to help you out.",
            "type": "Button",
            "backgroundType": "Grey",
            "buttonText": "Open",
            "buttonType": "Secondary Outline",
            "firstIconName": "headset",
            "firstIconType": "fas",
            "onClick": () => window.open(Routes.support),
            "isInvisibleWhenNoPermission": false
        },
    ]

    const [dayPart, setDayPart] = useState("");
    const [marketingContent, setMarketingContent] = useState({});
    const [filteredPageKeyWords, setFilteredPageKeyWords] = useState(PageKeywords);
    const user = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const viewPermissionKeys = [];
    Object.entries(viewPermissions).forEach(([key, value]) => {
        if (user.Permissions[0].Company.includes(value)) { viewPermissionKeys.push(key) }
    });
    if (user.Permissions[0].User.includes(viewPermissions.SLA)) {
        viewPermissionKeys.push("SLA")
    }

    const primaryRole = () => {
        if (user.Roles.some((role) => role.Name === "CTOUCH")) { return "User" }
        else if (user.Roles.some((role) => role.Name === "Reseller")) { return "Reseller" }
        else if (user.Roles.some((role) => role.Name === "Administrator")) { return "Admin" }
        else return "User"
    }

    useEffect(() => {
        var today = new Date()
        var curHr = today.getHours()

        if (curHr >= 6 && curHr < 12) {
            setDayPart('morning')
        } else if (curHr >= 12 && curHr < 18) {
            setDayPart('afternoon')
        } else if (curHr >= 18 && curHr < 23) {
            setDayPart('evening')
        }
        else {
            setDayPart('night')
        }

        AzureService.getMarketingContent(primaryRole()).then((response) => {
            setMarketingContent(response);
        })

        setFilteredPageKeyWords(() => {
            let newKeyWords = {};
            Object.entries(PageKeywords).filter(([pageKeyWord, pageName]) => {
                let lowerCasePageName = pageName.toLowerCase();
                viewPermissionKeys.forEach(key => { if (lowerCasePageName === key.toLowerCase()) { newKeyWords = { ...newKeyWords, [pageKeyWord]: [pageName] } } })
            })
            return newKeyWords
        })
    }, [])

    useEffect(() => {

    }, [user])

    return (
        <div className={styles.mainContainer}>
            <MainMenu />
            <div className={styles.body}>
                <div className={styles.header}>
                    <div className={styles.overlay}>
                        <span className={styles.dayPart}>Good {dayPart}</span>
                        <span className={styles.userName}>{user.FirstName}</span>
                        <SearchBar dropdownOptions={Object.keys(filteredPageKeyWords)} onSelectOption={(option) => { const routeName = filteredPageKeyWords[option]; navigate(Routes[routeName]) }} />
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.quickAccess}>
                        <TitleSubTitle className={styles.titleSubTitle} title={"Quick access"} subtitle={"Get instant access to your favourite items."} />
                        <CardGrid user={user} cards={quickAccess} />
                    </div>
                    <MarketingContent content={marketingContent} />
                    <div className={styles.apps}>
                        <TitleSubTitle className={styles.titleSubTitle} title={"MyCTOUCH apps"} subtitle={"Everything there is to discover within MyCTOUCH."} />
                        <CardGrid user={user} cards={apps} />
                    </div>

                </div>
            </div>
            {
                (user != null && (user.FirstName == null || user.LastName == null)) &&
                <UserNameModal />
            }
        </div>
    )
}

export default HomePage;