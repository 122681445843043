import { AxiosService } from './AxiosService';

const baseUrl = "/EchoHelloProfileSetting";
const axiosService = AxiosService(process.env.REACT_APP_API_URL)

export const EchoHelloProfileSettingService = {
    async updateProfileSetting(profileSetting) {
        return await axiosService.putRequest(`${baseUrl}`, profileSetting)
    },
    async pasteProfileSettings(copiedProfileId, toPasteProfileId) {
        return await axiosService.postRequest(`${baseUrl}/pasteSettings/${copiedProfileId}/${toPasteProfileId}`);
    },
    async inheritProfileSettings(inheritedProfileId, newProfileId) {
        return await axiosService.postRequest(`${baseUrl}/inheritSettings/${inheritedProfileId}/${newProfileId}`);
    },
}