import styles from './TableHeader.module.css';
import { ListItem, CheckBox } from '@ctouch-europe-b-v/myctouch-component-library';
import { MultiSelectContext, MultiSelectDispatchContext } from '../../../../contexts/MultiSelectContext';
import { useContext, useEffect, useState } from 'react';

const TableHeader = ({ gridStyle, className, data, headerData, checkboxColumn, actionColumn, multiSelectIdColumn, extraSelectColumn }) => {
  const multiSelected = useContext(MultiSelectContext);
  const dispatch = useContext(MultiSelectDispatchContext);
  const [allChecked, setAllChecked] = useState(multiSelected.length === data.length);

  useEffect(() => {
    setAllChecked(multiSelected.length === data.length && data.length > 0)
  }, [multiSelected, data])

  const selectAllHandler = (e) => {
    if (!allChecked) {
      const dataInfo = [];
      data.forEach(item => {
        extraSelectColumn !== undefined ? dataInfo.push({ type: "Add One", id: item[multiSelectIdColumn], [extraSelectColumn.column]: item[extraSelectColumn.value] })
          : dataInfo.push({ type: "Add One", id: item[multiSelectIdColumn] })
      })
      dispatch({
        type: "Add All",
        users: dataInfo
      })
    } else {
      dispatch({ type: "Remove All" })
    }
    setAllChecked(!allChecked)

  }

  const renderHeaders = () => {
    const items = [];
    headerData.forEach((header, index) => {
      if (checkboxColumn !== undefined && checkboxColumn === index) {
        items.push(<ListItem className={[styles.tableHeaderItem, styles.headerCheckBox].filter(e => !!e).join(" ")} type={"Title"} text={<CheckBox checked={allChecked} onClick={(e) => { selectAllHandler(e) }} />} />)
      }
      items.push(<ListItem className={[header.centered && styles.center, styles.tableHeaderItem, header.maxWidth && styles.maxWidth, header.fixedWidth && styles.fixedWidth].filter(e => !!e).join(" ")} type={"Title"} text={header.text} />)
      if (actionColumn !== undefined && actionColumn === index) {
        items.push(<ListItem className={[styles.center, styles.tableHeaderItem].filter(e => !!e).join(" ")} type={"Title"} text={"Actions"} />)
      }
    })
    return items
  }
  return (
    <div style={gridStyle} className={[styles.tableHeader, className].filter(e => !!e).join(" ")}>
      {renderHeaders()}
    </div>
  )
}

export default TableHeader