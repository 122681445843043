import { MenuItem, ContextMenuContainer, ContextMenuItem, BackgroundType, PopUpDirection } from '@ctouch-europe-b-v/myctouch-component-library';
import { useEffect, useRef, useState } from 'react';
import styles from "./ProfileMenuItem.module.css"
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import PasteSettingsModal from './../pasteSettingsModal/PasteSettingsModal';
import RemoveProfileModal from '../removeProfileModal/RemoveProfileModal';
import RenameProfileModal from './../renameProfileModal/RenameProfileModal';
import { ProfileService } from './../../../../services/ProfileService';
import { useDispatch, useSelector } from 'react-redux';
import { copy, copyEchoHello } from '../../../../redux/profile/profileSlice';
import useWindowDimensions from '../../../../hooks/useWindowsDimension';
import { ProfileSettingService } from '../../../../services/ProfileSettingService'
import { EchoHelloProfileService } from '../../../../services/EchoHelloProfileService';
import { EchoHelloProfileSettingService } from '../../../../services/EchoHelloProfileSettingService';

const ProfileMenuItem = ({ profile, isSelected, onClick, onProfilesAffected, isDisabled, isDefaultProfile, onProfilePasted, profileType, warning, setWarning }) => {
    const { height, width } = useWindowDimensions();
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)
    const [pasteSettingsOpen, setPasteSettingsOpen] = useState(false)
    const [removeProfileOpen, setRemoveProfileOpen] = useState(false)
    const [renameProfileOpen, setRenameProfileOpen] = useState(false)
    const [popupDirection, setPopupDirection] = useState(PopUpDirection.topLeft);


    const copiedProfileId = useSelector((state) => state.profile.profileId)
    const copiedEchoHelloProfileId = useSelector((state) => state.echoHelloProfile.profileId)
    const dispatch = useDispatch()
    const ref = useRef()
    const contextRef = useRef()
    useOnClickOutside(ref, () => { if (isContextMenuOpen) { setIsContextMenuOpen(false) } })
    useEffect(() => {
        if (isContextMenuOpen) {
            const divRect = ref.current.getBoundingClientRect();
            const contextRect = contextRef.current.getBoundingClientRect();
            contextRef.current.style.left = `calc(${divRect.right}px - 30px)`;
            if (contextRect.top + contextRect.height > (height)) {
                contextRef.current.style.top = ` calc((${divRect.top}px - ${contextRect.height}px) - 40px)`;
                setPopupDirection(PopUpDirection.bottomLeft)
            } else {
                contextRef.current.style.top = `calc(${divRect.bottom}px - 8px)`
                setPopupDirection(PopUpDirection.topLeft)
            }
        }
    }, [isContextMenuOpen, height])

    const removeProfile = () => {
        switch (profileType) {
            case "EchoHello":
                EchoHelloProfileService.deleteProfile(profile.Id).then(() => {
                    setRemoveProfileOpen(false);
                    onProfilesAffected(1)
                });
                break;
            default:
                ProfileService.deleteProfile(profile.Id).then(() => {
                    setRemoveProfileOpen(false);
                    onProfilesAffected()
                });
                break;
        }
    }

    const pasteSettings = () => {
        if (copiedProfileId || copiedEchoHelloProfileId) {
            switch (profileType) {
                case "EchoHello":
                    EchoHelloProfileSettingService.pasteProfileSettings(copiedEchoHelloProfileId, profile.Id).then(() => {
                        setPasteSettingsOpen(false)
                    })
                    onProfilePasted(profile.Id)
                    break;
                default:
                    ProfileSettingService.pasteProfileSettings(copiedProfileId, profile.Id).then(() => {
                        setPasteSettingsOpen(false)
                    })
                    onProfilePasted(profile.Id)
                    break;
            }
        }
    }

    const handleCopySettings = () => {
        switch (profileType) {
            case "EchoHello":
                dispatch(copyEchoHello(profile.Id));
                break;
        
            default:
                dispatch(copy(profile.Id)); 
                break;
        }
        setIsContextMenuOpen(false)
    }

    const handleSaveProfile = (e) => {
        switch (profileType) {
            case "EchoHello":
                EchoHelloProfileService.updateProfile(e).then(() => {
                    setRenameProfileOpen(false)
                })
                    .catch((error) => {
                        if (error.response && error.response.status === 400) {
                            if (error.response.data[0] === "This profile already exists"){
                                setWarning({ type: "profileAlreadyExists" })
                            }
                        }
                    });
                break;
            default:
                ProfileService.updateProfile(e).then(() => {
                    setRenameProfileOpen(false)
                }); 
                break;
        }
    }

    return (
        <div className={styles.container}>
            <MenuItem isDisabled={isDisabled} isSelected={isSelected} type="Item" text={profile.ProfileName} onIconClick={() => setIsContextMenuOpen(!isContextMenuOpen)} onClick={() => onClick(profile)} />
            {isContextMenuOpen &&
                <div ref={ref} >
                    <ContextMenuContainer ref={contextRef} popupDirection={popupDirection} backgroundType={BackgroundType.grey} className={styles.position}>
                        <ContextMenuItem isDisabled={profile.ProfileName === "Default"} type="Item" text="Rename" iconName="pen" onClick={() => setRenameProfileOpen(true)} />
                        <ContextMenuItem type="Divider" />
                        <ContextMenuItem type="Item" text="Copy Settings" iconName="copy" onClick={() => { handleCopySettings() }} />
                        <ContextMenuItem isDisabled={profile.ProfileName === "Default"} type="Item" text="Paste Settings" iconName="paste" onClick={() => setPasteSettingsOpen(true)} />
                        <ContextMenuItem type="Divider" />
                        <ContextMenuItem isDisabled={profile.ProfileName === "Default"} type="Item" version="Warning" text="Remove" iconName="trash" onClick={() => setRemoveProfileOpen(true)} />
                    </ContextMenuContainer>
                </div>
            }
            {pasteSettingsOpen && <PasteSettingsModal onPaste={() => { pasteSettings() }} onCancel={() => setPasteSettingsOpen(false)} />}
            {removeProfileOpen && <RemoveProfileModal onRemove={() => removeProfile()} onCancel={() => setRemoveProfileOpen(false)} />}
            {renameProfileOpen && <RenameProfileModal profile={profile} onSave={(e) => { handleSaveProfile(e) }} onCancel={() => {setRenameProfileOpen(false); setWarning() }} warning={warning} />}
        </div>
    );
}

export default ProfileMenuItem;