import styles from './ContextMenuLicenceTable.module.css';
import { ContextMenuContainer, ContextMenuItem } from '@ctouch-europe-b-v/myctouch-component-library';
import { forwardRef, useContext, useEffect } from 'react';
import useUpdateUser from '../../../../hooks/useUpdateUser';
import { MultiSelectDispatchContext } from '../../../../contexts/MultiSelectContext';
import { EchoHelloLicenceService } from '../../../../services/EchoHelloLicenceService';

const ContextMenuLicenceTable = forwardRef(({ popupDirection, licenceId, deviceName, profileName, className, refresh }, ref) => {
  const unlinkHandler = async () => {
    await EchoHelloLicenceService.unlinkLicenceFromDevice(licenceId);
    refresh()
  }

  const resetProfileHandler = async () => {
    await EchoHelloLicenceService.updateLicense(licenceId, 1);
    refresh()
  }

  const removeProfileHandler = async () => {
    await EchoHelloLicenceService.removeProfile(licenceId);
    refresh()
  }
 
  return (
    <ContextMenuContainer ref={ref} className={[styles.customWidth, className].filter(e => !!e).join(" ")} backgroundType={"White"} popupDirection={popupDirection}>
      <ContextMenuItem isDisabled={deviceName === null} backgroundType={"White"} type={"Item"} text={"Unlink from device"} iconName={"link-simple-slash"} onClick={() => unlinkHandler()} />
      <ContextMenuItem isDisabled={profileName === "Default"} backgroundType={"White"} type={"Item"} text={"Reset to default profile"} iconName={"arrows-rotate"} onClick={() => resetProfileHandler()} />
      <ContextMenuItem  backgroundType={"White"} type={"Divider"} version={"Grey"} />
      <ContextMenuItem  backgroundType={"White"} type={"Item"} text={"Unlink from account"} iconName={"trash"} version={"Warning"} onClick={() => removeProfileHandler()} />
    </ContextMenuContainer>
  )

})

export default ContextMenuLicenceTable;