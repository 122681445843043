import styles from './TableRow.module.css';
import { ListItem, PopUpDirection } from '@ctouch-europe-b-v/myctouch-component-library';
import ContextMenuVerifyDenyUser from '../../organisms/contextMenuVerifyDenyUser/ContextMenuVerifyDenyUser';
import { MultiSelectContext, MultiSelectDispatchContext } from '../../../../contexts/MultiSelectContext';
import { useState, useRef, useEffect, useContext } from 'react';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import ContextMenuLicenceTable from '../../organisms/contextMenuLicenceTable/ContextMenuLicenceTable';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

const AccountManagementTableRow = ({ gridStyle, dataItem, currentUser, setActiveModal, className, icons, contextMenuType, multiSelectIdColumn, extraSelectColumn, rowColumns, actionColumn, checkboxColumn, refresh }) => {
  const { height, width } = useWindowDimensions();
  const [showContext, setShowContext] = useState(false);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [contextRect, setContextRect] = useState();
  const [popupDirection, setPopupDirection] = useState(PopUpDirection.topLeft);
  const multiSelected = useContext(MultiSelectContext);
  const dispatch = useContext(MultiSelectDispatchContext);
  const [isChecked, setIsChecked] = useState(false);
  const windowSize = useRef([
    window.innerWidth,
    window.innerHeight
  ])
  const menuRef = useRef();
  useEffect(() => {
    setIsChecked(multiSelected.filter(selectedRow => selectedRow.id === dataItem[multiSelectIdColumn]).length !== 0)
  }, [multiSelected, dataItem[multiSelectIdColumn]])
  useEffect(() => {
    if (showContext) {
      const rect = menuRef.current.getBoundingClientRect();
      menuRef.current.style.left = `50%`;
      if (rect.right > (width - 50)) {
        menuRef.current.style.left = `calc(50% - ${rect.width - 2}px)`;
        setPopupDirection(PopUpDirection.topRight)
      } else {
        setPopupDirection(PopUpDirection.topLeft)
      }
      setContextRect(prev => prev = rect)
    }
  }, [showContext, width])
  useOnClickOutside(menuRef, () => setShowContext(false))
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  const selectHandler = (e) => {
    if (isChecked) {
      dispatch({ type: "Remove One", id: dataItem[multiSelectIdColumn] })
    } else {
      const selectObject = { type: "Add One", id: dataItem[multiSelectIdColumn] }
      extraSelectColumn !== undefined && extraSelectColumn.forEach(extra => selectObject[extra.column] = getNestedValue(dataItem, extra.value));

      // extraSelectColumn !== undefined ? 
      dispatch(selectObject)
      // : dispatch({ type: "Add One", id: dataItem[multiSelectIdColumn] })
    }
    setIsChecked(!isChecked)
  }

  const dateChecker = (date) => {
    if (date === null) { return "-" }
    const currentDate = new Date();
    const splitDate = date.split("-")

    const dbDate = new Date(date);
    date = `${splitDate[0]}-${splitDate[1]}-${splitDate[2].substring(0, 2)}`

    return <>{dbDate <= currentDate ? <span className={styles.warning}>{date}</span> : date}</>
  }

  const contextHandler = () => {
    setShowContext(prev => !prev);
  }

  const renderContextMenu = () => {
    switch (contextMenuType) {
      case "accountManagement":
        return <ContextMenuVerifyDenyUser isVerified={dataItem?.UserStatus === "Verified" || dataItem?.UserStatus === "Invited"} isDenied={dataItem?.UserStatus === "Denied" || dataItem?.UserStatus === "Invited"} className={[styles.topLeft, styles.normal].filter(e => !!e).join(" ")} ref={menuRef} userId={dataItem.Email} setActiveModal={setActiveModal} popupDirection={popupDirection} />
      case "echoHelloLicence":
        return <ContextMenuLicenceTable className={styles.normal} ref={menuRef} deviceName={dataItem.DeviceName} profileName={dataItem?.Profile?.ProfileName || ""} licenceId={dataItem.LicenceKey} popupDirection={popupDirection} refresh={refresh} />
    }
  }



  const renderRow = () => {
    const items = [];
    rowColumns.forEach((column, index) => {
      const columnValue = getNestedValue(dataItem, column.columnName)
      if (checkboxColumn !== undefined && checkboxColumn === index) {
        items.push(<span className={[styles.tableRowItem, styles.check].filter(e => !!e).join(" ")}><ListItem isHovered={isRowHovered} className={[styles.columnContent].filter(e => !!e).join(" ")} checked={isChecked} type={"Check"} value={dataItem[multiSelectIdColumn]} onClick={(e) => { selectHandler(e) }} /></span>)
      }
      if (column.columnName === "UserStatus") {
        items.push(<span className={[styles.tableRowItem, column.centered && styles.center].filter(e => !!e).join(" ")}><ListItem isHovered={isRowHovered} className={[styles.columnContent, styles.status,].filter(e => !!e).join(" ")} type={column.type} iconColor={icons(dataItem.UserStatus).color} iconName={icons(dataItem.UserStatus).name} text={dataItem.UserStatus !== null ? dataItem.UserStatus : "Unknown"} /></span>)
      } else {
        items.push(<span className={[styles.tableRowItem, column.centered && styles.center, column.maxWidth && styles.maxWidth, column.fixedWidth && styles.fixedWidth].filter(e => !!e).join(" ")}><ListItem
          isHovered={isRowHovered}
          className={[styles.columnContent].filter(e => !!e).join(" ")}
          type={column.type === "Date" ? "Text" : column.type}
          text={column.columnName === "Email" ? <>{dataItem.Email} <span className={styles.activeUser}>{dataItem.Email === currentUser.Email && '(you)'}</span></> : column.type === "Date" ? dateChecker(columnValue) : columnValue === null ? <span className={styles.undefined}> Unassigned </span> : columnValue}
          isDisabled={!column?.disabledValue?.includes(dataItem[column.disabledColumn])}
          onClick={(e) => column?.clickFunction(e)}
          onChange={(e) => column?.changeFunction(e, dataItem[column.compareColumn], dataItem)}
          checked={dataItem[column.checkedColumn] === 1}
          value={column.checkedColumn !== undefined ? dataItem[column.checkedColumn] === 1 : column.value}
        /></span>)
      }
      if (actionColumn !== undefined && actionColumn === index) {
        items.push(<span className={[styles.tableRowItem].filter(e => !!e).join(" ")}><ListItem isHovered={isRowHovered} className={[styles.columnContent, styles.menu].filter(e => !!e).join(" ")} innerRef={menuRef} type={"Menu"} onClick={() => { contextHandler() }} >
          {showContext && renderContextMenu()}
        </ListItem></span>)
      }
    });
    return items
  }


  return (
    <div style={gridStyle} onMouseEnter={() => { setIsRowHovered(true) }} onMouseLeave={() => setIsRowHovered(false)} className={[styles.tableRow, className, isRowHovered && styles.hover].filter(e => !!e).join(" ")}>
      {renderRow()}
    </div>
  )
}

export default AccountManagementTableRow;