import ReleaseParagraphs from "../../atoms/releaseParagraphs/ReleaseParagraphs";
import styles from "./ReleaseNoteModal.module.css";
import { ModalContainer, ModalTitle, ModalParagraph, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"

const ReleaseNoteModal = ({ onClose }) => {
  return (
    <ModalContainer hasOverlay onClick={() => { onClose() }}>
      <ModalTitle title={"Release Notes"} />
      <ReleaseParagraphs icon={"stars"} paragraphs={["Echo Hello integration to MyCTOUCH added", "Removed UboardMix"]} />
      <ReleaseParagraphs icon={"wrench"} paragraphs={["Temporarily Removed cards for Echo and Origin from Homepage", "Added cards for Echo Hello to Homepage"]} />
      <ModalParagraph version={"Secondary"} type={"Info"} text={"V Echo Hello - Echo Hello Integration"} />
      <ModalButtons cancelButton cancelButtonText="Close" onCancelButtonClick={() => { onClose() }} />
    </ModalContainer>
  )
}

export default ReleaseNoteModal