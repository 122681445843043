import { ModalParagraph } from "@ctouch-europe-b-v/myctouch-component-library";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ReleaseParagraphs.module.css'

const ReleaseParagraphs = ({ paragraphs, icon }) => {

  return (
    <div className={styles.container}>
      {paragraphs.map((paragraph, index) => {
        return (<div className={styles.wrapper}>
          {index === 0 ? <FontAwesomeIcon icon={["fal", icon]} /> : <span className={styles.empty}></span>}
          <ModalParagraph text={paragraph} className={[styles.listItem, styles.paragraph].filter(e => !!e).join(" ")} />
        </div>)
      })}
    </div>
  )

}

export default ReleaseParagraphs