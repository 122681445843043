import { ModalContainer, ModalTitle, ModalParagraph, ModalInputField, ModalButtons } from "@ctouch-europe-b-v/myctouch-component-library"
import { useEffect, useState } from "react";

const AddProfileModal = ({ profiles, onCancel, onSave, warning, setWarning }) => {
    const [name, setName] = useState("")
    const [profile, setProfile] = useState(profiles[0])

    return (
        <ModalContainer hasOverlay>
            <ModalTitle title={"New Profile"} />
            <ModalParagraph type="Info" text={"You can use an existing profile as a starting point and inherit its settings."} />
            <ModalInputField type="Text" placeholder="Name" description="Name" value={name} onChange={(e) => { setName(e.target.value) }} />
            <ModalInputField type="Dropdown" onChange={(e) => { setProfile(profiles.filter((profile) => profile.ProfileName === e.target.value)[0]); }} description="Inherit from" value={profile?.ProfileName} options={profiles.map((profile) => {
                return { "text": profile.ProfileName }
            })} />
            {warning && warning.type === "profileAlreadyExists" && <ModalParagraph color={"#EB3232"} text={"This profile already exists."} type={"Info"} version={"Secondary"} />}
            <ModalButtons primaryButton cancelButton onCancelButtonClick={onCancel} primaryButtonText="Save" onPrimaryButtonClick={() => { onSave({ name: name, inherited: profile.Id }) }} />
        </ModalContainer>
    )
}

export default AddProfileModal;