import styles from './ModalInviteUser.module.css';
import { useState } from 'react';
import { ModalContainer, ModalParagraph, ModalTitle, ModalButtons, ButtonType, ModalInputField } from '@ctouch-europe-b-v/myctouch-component-library';
import { useSelector } from 'react-redux';
import { MailService } from '../../../../services/MailService';
import { SphereUserService } from '../../../../services/SphereUserService';

const ModalInviteUser = ({ userId, setActiveModal, setSnack }) => {
  const user = useSelector(state => state.user.value);
  const selectedCompany = useSelector(state => state.selectedCompany.value)
  const company = useSelector(state => state.company.value)
  const [activeInput, setActiveInput] = useState("");
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [warning, setWarnings] = useState(false)
  const [email, setEmail] = useState("");

  const handleInviteUser = () => {
    const currentCompany = selectedCompany === null ? company : selectedCompany

    let activeUserEmail = selectedCompany !== null ? selectedCompany.EmailDomainName : user.Email.split("@")[1];
    let errors = false
    if (email === "") { setWarnings({ type: "emptyEmail" }); errors = true }
    if (email.split("@")[1] !== activeUserEmail) { setWarnings({ type: "incorrectEmail" }); errors = true }
    if (!errors) {
      MailService.sentInviteUser({ mail: email }).then((response) => {

        if (response.code === 200) {
          // TODO create user with user_status invited, and company_id of inviter
          SphereUserService.create({ email: email, company_id: currentCompany.Id, user_status: "Invited", sphere_access: 0, first_name: firstName, last_name: lastName, verified_by_admin: 1 }).then(() => {
            setWarnings(false)
            setSnack({ type: "InviteUser", val: email })
          })
        } else {
          setSnack({ type: "error" })
        }
        setActiveModal("none")
      })
    }
  }
  return (
    <ModalContainer hasOverlay className={styles.height} onClick={() => setActiveModal("none")}>
      <ModalTitle title={"Invite User"} />
      <ModalParagraph className={styles.text} text={"Be aware that the new user must be within the same email domain as your organization."} type={"Info"} version={"Secondary"} />
      <ModalInputField description={"First Name"} type={"Text"} backgroundType={"White"} onChange={(e) => setFirstName(e.target.value !== "" ? e.target.value : null)} value={firstName !== null ? firstName : undefined} onClick={() => setActiveInput("firstName")} placeholder={"First Name"} state={activeInput === "firstName" ? "Active" : "Empty"} />
      <ModalInputField description={"Last Name"} type={"Text"} backgroundType={"White"} onChange={(e) => setLastName(e.target.value !== "" ? e.target.value : null)} value={lastName !== null ? lastName : undefined} onClick={() => setActiveInput("lastName")} placeholder={"Last Name"} state={activeInput === "lastName" ? "Active" : "Empty"} />
      <ModalInputField description={"Email"} type={"Text"} backgroundType={"White"} onChange={(e) => { setEmail(e.target.value); setWarnings(false) }} value={email} onClick={() => setActiveInput("email")} placeholder={"Email"} state={activeInput === "email" ? "Active" : warning ? "Warning" : "Empty"} />
      {warning && warning.type === "emptyEmail" && <ModalParagraph color={"#EB3232"} text={"Email is required"} type={"Info"} version={"Secondary"} />}
      {warning && warning.type === "incorrectEmail" && <ModalParagraph color={"#EB3232"} text={"Entered email is incorrect, only same domain as company allowed"} type={"Info"} version={"Secondary"} />}
      <ModalButtons cancelButton cancelButtonType={ButtonType.secondaryOutline} onCancelButtonClick={() => setActiveModal("none")} primaryButton primaryButtonText={"Invite User"} primaryButtonType={ButtonType.primary} onPrimaryButtonClick={() => { handleInviteUser() }} />
    </ModalContainer>
  )
}

export default ModalInviteUser;