import { useEffect, useState } from 'react';
import styles from './App.module.css';
import Echo from './components/pages/echo/Echo';
import EchoHello from './components/pages/echoHello/EchoHello';
import { Footer, ContextMenuContainer, ContextMenuItem, BackgroundType, PopUpDirection, Snackbar } from '@ctouch-europe-b-v/myctouch-component-library';
import { useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react'
import useOnClickOutside from './hooks/useOnClickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyService } from './services/CompanyService';
import { setCompany, setCompanyList, setCompanyWithLocationsAndDisplays } from "./redux/company/companySlice"
import AccountManagement from './components/pages/accountManagement/AccountManagement';
import HomePage from './components/pages/homePage/HomePage';
import { Routes, Route, Navigate } from "react-router-dom";
import { default as routes } from './enums/Routes';
import Registration from './components/pages/registration/Registration';
import SettingsPage from "./components/pages/settingsPage/SettingPage";
import OriginPage from "./components/pages/originPage/OriginPage";
import { setUser, setUserList } from './redux/user/userSlice';
import LandingPage from './components/pages/landingPage/LandingPage';
import { UserService } from './services/UserService';
import { useLocation } from 'react-router-dom';
import { MultiSelectProvider } from './contexts/MultiSelectContext';
import SlaChecker from './components/pages/slaChecker/SlaChecker';
import Permissions from './enums/Permissions';
import MainMenu from './components/ui/molecules/mainMenu/MainMenu';
import LoadingPage from './components/pages/loadingPage/LoadingPage';
import NotFoundPage from './components/pages/notFoundPage/NotFoundPage';
import { UserRoleService } from './services/UserRoleService';
import ReleaseNoteModal from './components/ui/organisms/releaseNoteModal/ReleaseNoteModal';

const currentDateToISO = () => {
  const currentDate = new Date();
  const isoDate = currentDate.toISOString();

  return isoDate;
}

const addDateToISO = (year) => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() + year);
  const isoDate = currentDate.toISOString();

  return isoDate;
}

const App = () => {
  const [isInfoMenuOpen, setIsInfoMenuOpen] = useState(false);
  const [snackBar, setSnackBar] = useState(false)
  const viewPermissions = Permissions.read;
  const ref = useRef()
  const [isDataFetched, setIsDataFetched] = useState(false)
  const { logout, user, isAuthenticated, isLoading } = useAuth0();
  const pagesWithMenu = [routes.accountManagement, routes.echo, routes.echoHello, routes.sla, routes.settings, routes.origin];
  useOnClickOutside(ref, () => { if (isInfoMenuOpen) { setIsInfoMenuOpen(false) } })
  const dispatch = useDispatch()
  const location = useLocation()
  const loggedInUser = useSelector((state) => state.user.value)
  const [activeCompanyPermissions, setActiveCompanyPermissions] = useState();
  const [activeUserPermissions, setActiveUserPermissions] = useState();
  const [firstTimeLoginSnack, setFirstTimeLoginSnack] = useState(false)
  const [newUser, setNewUser] = useState(false);
  const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);

  useEffect(() => {
    const delay = ms => new Promise(r => setTimeout(r, ms))
    async function fetchData() {
      setIsDataFetched(false)
      let aNewUser = false;
      let dbUser = await getUser(user).catch(e => {
        if (JSON.parse(e.request.response)[0] === "The user is not known in our data set") {
          setNewUser(true)
          aNewUser = true;
        }
      })
      if (dbUser?.UserStatus === "Pending") {
        aNewUser = true
        setNewUser(true)
      }
      if (dbUser.UserStatus === "Invited") {
        dbUser.UserStatus = "Verified";
        await UserService.updateUser(dbUser).then(() => {
          dispatch(setUser(dbUser))
        })
      }
      let rolesFetched = 0;
      if (aNewUser === false) {
        // any user without the User role should be assigned the User role
        if (dbUser.Roles === undefined || dbUser.Roles.length === 0 || !dbUser.Roles.some(role => role.Name === "User")) {
          setFirstTimeLoginSnack(true)
          await createRole("User", dbUser.Email).catch(e => console.log(e));
          rolesFetched = 1
        }

        //Any user that has isAdmin true should be assigned the Administrator role
        if (dbUser.IsAdmin === 1 && !dbUser.Roles.some(role => role.Name === "Administrator")) {
          setFirstTimeLoginSnack(prev => { if (!prev) return true })
          await createRole("Administrator", dbUser.Email).catch(e => console.log(e));
          rolesFetched = 1
        }

        // if any roles have been created for User, set them in the User object
        if (rolesFetched === 1) {
          await delay(3000);
          dbUser = await getUser(user);
        }

        //If, for some reason, UserStatus isn't set, set it
        if (dbUser.UserStatus === null) {
          dbUser.UserStatus = dbUser.VerifiedByAdmin && !dbUser.IsDenied ? "Verified" : dbUser.IsDenied || !dbUser.VerifiedByAdmin ? "Denied" : "Pending"
          dbUser.SphereAccess = dbUser.UserStatus === "Verified" ? 1 : 0
          UserService.updateUser(dbUser);
        }
        dispatch(setUser(dbUser))
        const dbCompany = await getCompany(dbUser.CompanyId).catch(e => console.log(e));
        dispatch(setCompany(dbCompany));
        dispatch(setCompanyWithLocationsAndDisplays(dbCompany));
        // If user only has "User" role, this should be skipped

        if (dbUser.Roles.filter(role => role.Name !== "Reseller" && role.Name !== "CTOUCH" && role.Name !== "Administrator").length <= 1) {
          const usersInCompany = await getAllUsersFromCompany(dbCompany.Id).catch(e => console.log(e));
          dispatch(setUserList(usersInCompany))
        }

        // Checks what company Type the company the user belongs to is, with Reseller and CTOUCH getting a companyList with the company they have access to
        const companyType = dbUser.Roles.some(role => role.Name === "Reseller") && !dbUser.Roles.some(role => role.Name === "CTOUCH") ? "Reseller" : !dbUser.Roles.some(role => role.Name === "Reseller") && dbUser.Roles.some(role => role.Name === "CTOUCH") ? "CTOUCH" : "None"
        const dbCompanyList = await getCompanyList(companyType, dbCompany).catch(e => console.log(e))
        dispatch(setCompanyList(dbCompanyList))

        // change isDataFetched state to true after everything
        setFirstTimeLoginSnack(false)
        setIsDataFetched(true)
      } else {
        setIsDataFetched(true)
      }

    }
    if (isAuthenticated) {
      fetchData()
    }
  }, [user, isAuthenticated])

  const createRole = async (roleName, email) => {
    return new Promise((resolve, reject) => {
      UserRoleService.create({
        userEmail: email,
        roleName: roleName,
        authorization: {
          grantedByEmail: "authorization@ctouch.eu",
          startDate: currentDateToISO(),
          endDate: addDateToISO(2)
        }
      }).then((res) => {
        resolve()
      }).catch(e => reject(e))
    })
  }

  const getUser = async (user) => {
    return new Promise((resolve, reject) => {
      UserService.getUser(user.email).then((dbUser) => {
        dbUser.sso = user.sub.startsWith("waad|");
        setActiveCompanyPermissions(dbUser.Permissions[0] !== undefined || dbUser.Permissions[0] !== null ? dbUser.Permissions[0].Company : []);
        setActiveUserPermissions(dbUser.Permissions[0] !== undefined || dbUser.Permissions[0] !== null ? dbUser.Permissions[0].User : []);
        resolve(dbUser)
      }).catch(e => reject(e))
    })
  }

  const getCompany = async (id) => {
    return new Promise((resolve, reject) => {
      CompanyService.getCompany(id).then((company) => {
        resolve(company)
      }).catch(e => reject(e))
    })
  }

  const getAllUsersFromCompany = async (id) => {
    return new Promise((resolve, reject) => {
      UserService.getAllUsersFromCompany(id).then((userList) => {
        resolve(userList);
      }).catch(e => reject(e))
    })
  }

  const getCompanyList = (type, company) => {
    return new Promise((resolve, reject) => {
      if (type === "Reseller") {
        CompanyService.getCompaniesWithResellerCode(company.ExternalToUserCode).then(companyList => {
          resolve(companyList)
        }).catch(e => reject(e))
      } else if (type === "CTOUCH") {
        CompanyService.getCompaniesWithCtouchSupport().then(companyList => {
          resolve(companyList)
        }).catch(e => reject(e))
      } else {
        resolve([])
      }
    })
  }

  const elementRender = (element = null, permissions = null) => {
    if (isDataFetched) {
      if (permissions === null && (loggedInUser?.UserStatus !== null && loggedInUser?.UserStatus !== "Verified")) {
        return isAuthenticated && newUser ? <Registration /> : <LandingPage />
      }
      if (permissions === null) {
        return element
      }
      else if (permissions !== null && permissions?.length === 2) {

        return activeCompanyPermissions?.includes(permissions[0].name) && activeUserPermissions?.includes(permissions[1].name) ? element : <Navigate to={routes.home} />
      }
      else if (permissions !== null && permissions?.length === 1) {
        return permissions[0].type === "Company" && activeCompanyPermissions?.includes(permissions[0].name) ? element : permissions[0].type === "User" && activeUserPermissions?.includes(permissions[0].name) ? element : <Navigate to={routes.home} />
      }
      else return <HomePage />
    } else {
      return <LandingPage />
    }
  }

  const logFunction = (log) => {
    console.log(log)
    return true
  }
  return (
    <div className={styles.mainContainer}>
      {releaseNotesOpen && <ReleaseNoteModal onClose={() => setReleaseNotesOpen(false)} />}
      {((isDataFetched || !isAuthenticated) && !isLoading) ?
        <>
          <Routes>
            <>
              <Route path={routes.home} element={elementRender(<HomePage />)} />
              <Route path={routes.echo} element={elementRender(<Echo />, [{ type: "Company", name: viewPermissions.echo }, { type: "User", name: viewPermissions.echoView }])} />
              <Route path={routes.echoHello} element={elementRender(<MultiSelectProvider> <EchoHello /> </MultiSelectProvider>, [{ type: "User", name: viewPermissions.echoHelloView }])} />
              <Route path={routes.accountManagement} element={elementRender(<MultiSelectProvider> <AccountManagement /></MultiSelectProvider>, [{ type: "User", name: viewPermissions.accountManagement }])} />
              <Route path={routes.settings} element={elementRender(<SettingsPage auth0User={user} />, [{ type: "Company", name: viewPermissions.settings }])} />
              <Route path={routes.origin} element={elementRender(<OriginPage />, [{ type: "Company", name: viewPermissions.origin }, { type: "User", name: viewPermissions.originView }])} />
              <Route path={routes.sla} element={elementRender(<SlaChecker />, [{ type: "User", name: viewPermissions.SLA }])} />
              <Route path="*" element={elementRender(<NotFoundPage />)} />
            </>
          </Routes>
          <div>
            {((loggedInUser?.UserStatus === "Verified")) && <Footer withMenu={pagesWithMenu.includes(location.pathname.replace('/', ""))} className={[styles.footer, styles.footerStyling].filter(e => !!e).join(" ")} onClick={() => { setIsInfoMenuOpen(!isInfoMenuOpen) }} />}
            {isInfoMenuOpen &&
              <div ref={ref}>
                <ContextMenuContainer popupDirection={PopUpDirection.bottomRight} backgroundType={BackgroundType.grey} className={styles.contextMenu}>
                  <ContextMenuItem type="Item" text="Privacy Statement" iconName={"lock"} onClick={() => window.open("https://ctouch.eu/privacy-statement", "_blank", "noreferrer")} />
                  <ContextMenuItem type="Item" text="Cookie Statement" iconName="cookie" onClick={() => window.open("https://ctouch.eu/uk/cookies", "_blank", "noreferrer")} />
                  <ContextMenuItem type="Divider" />
                  <ContextMenuItem type="Item" text="Release Notes" iconName="note" onClick={() => { setReleaseNotesOpen(true) }} />
                  <ContextMenuItem type="Item" text="Contact Us" iconName="envelope" onClick={() => window.open("https://ctouch.eu/nl/contact", "_blank", "noreferrer")} />
                  <ContextMenuItem type="Divider" />
                  <ContextMenuItem type="Item" text="Logout" version="Warning" iconName="right-from-bracket" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} />
                </ContextMenuContainer>
              </div>
            }
            <Snackbar open={snackBar !== false} backgroundType={BackgroundType.white} text={snackBar} onClose={() => setSnackBar(false)} />
          </div>
        </>
        :
        <>
          <LoadingPage />
          <div>
            <Footer className={[styles.footer, styles.footerStyling].filter(e => !!e).join(" ")} onClick={() => { setIsInfoMenuOpen(!isInfoMenuOpen) }} />
            {isInfoMenuOpen &&
              <div ref={ref}>
                <ContextMenuContainer popupDirection={PopUpDirection.bottomRight} backgroundType={BackgroundType.grey} className={styles.contextMenu}>
                  <ContextMenuItem type="Item" text="Privacy Statement" iconName={"lock"} onClick={() => window.open("https://ctouch.eu/privacy-statement", "_blank", "noreferrer")} />
                  <ContextMenuItem type="Item" text="Cookie Statement" iconName="cookie" onClick={() => window.open("https://ctouch.eu/uk/cookies", "_blank", "noreferrer")} />
                  <ContextMenuItem type="Divider" />
                  <ContextMenuItem type="Item" text="Release Notes" iconName="note" onClick={() => { }} />
                  <ContextMenuItem type="Divider" />
                  <ContextMenuItem type="Item" text="User Manual" iconName="book" onClick={() => window.open("https://support.ctouch.eu/hc/en-us/articles/4409052450066-User-Manual", "_blank", "noreferrer")} />
                  <ContextMenuItem type="Item" text="Contact Us" iconName="envelope" onClick={() => window.open("https://ctouch.eu/nl/contact", "_blank", "noreferrer")} />
                  <ContextMenuItem type="Divider" />
                  <ContextMenuItem type="Item" text="Logout" version="Warning" iconName="right-from-bracket" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} />
                </ContextMenuContainer>
              </div>
            }
            <Snackbar open={snackBar !== false} backgroundType={BackgroundType.white} text={snackBar} onClose={() => setSnackBar(false)} />
            <Snackbar open={firstTimeLoginSnack !== false} backgroundType={BackgroundType.white} text={"Setting up account. Please wait..."} onClose={() => setFirstTimeLoginSnack(false)} />
          </div>
        </>
      }
    </div >
  )
}

export default App;
