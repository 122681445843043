import axios from 'axios';
import { AxiosService } from './AxiosService';

const baseUrl = "/EchoHelloLicenceKey";
const axiosService = AxiosService(process.env.REACT_APP_API_URL)

export const EchoHelloLicenceService = {
  async getAllLicences(companyId) {
    return await axiosService.getRequest(`${baseUrl}/getAllLicencesFromCompany/${companyId}`);
  },
  async updateLicense(license, profileId) {
    return await axiosService.putRequest(`${baseUrl}/assignProfileToLicence/${license}/${profileId}`);
  },
  async unlinkLicenceFromDevice(licence) {
    return await axiosService.putRequest(`${baseUrl}/unlinkLicenceFromDevice/${licence}`);
  },
  async addLicenceToCompany(licence, companyId, profileId) {
    return await axiosService.putRequest(`${baseUrl}/addLicenceToCompany/${licence}/${companyId}/${profileId}`);
  },
  async removeProfile(licence) {
    return await axiosService.putRequest(`${baseUrl}/resetLicence/${licence}`);
  }
}