import styles from './ModalAddLicence.module.css';
import { ModalContainer, ModalInputField, ModalTitle, ModalParagraph, ModalButtons, ButtonType } from "@ctouch-europe-b-v/myctouch-component-library";
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from "react";

const ModalAddLicence = ({ profiles, onSave, onCancel, warning, setWarning }) => {
  const [licenceCode, setLicenceCode] = useState("");
  const [activeInput, setActiveInput] = useState("");
  const [profile, setProfile] = useState(profiles[0]);
  
  const inputRef = useRef();

  const saveHandler = () => {
    if (/^([A-Z0-9]{4}-){3}[A-Z0-9]{4}$/.test(licenceCode)) {
      onSave({ licenceCode: licenceCode, profileName: profile.ProfileName, profileId: profile.Id })
    } else {
      setWarning({ type: "invalidLicence" })
    }
  }

  const licenceHandler = (e) => {
    const max = 16;
    const value = e.target.value.toUpperCase();
    const cursorPosition = e.target.selectionStart
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      return value;
    }
    const uppercaseValue = value.split("-").join("");
    if (uppercaseValue.length > max) {
      return value.substring(0, max + ((max / 4) - 1))
    }
    const valueArray = uppercaseValue.split("");
    let finalValue = ''
    valueArray.forEach((value, index) => {
      finalValue = `${finalValue}${value}`
      if ((index + 1) % 4 === 0 && (index + 1) !== max) {
        finalValue = `${finalValue}-`
      }
    })
    // reset the cursor position to what it was before manipulating the value string
    setTimeout(() => {
      e.target.selectionStart = cursorPosition;
      e.target.selectionEnd = cursorPosition;
    }, 0)

    return finalValue
  }

  return (
    <ModalContainer hasOverlay onClick={() => onCancel()}>
      <ModalTitle title={"Add License"} />
      <ModalParagraph className={styles.text} text={"Purchased licenses will be added automatically, but you can add a license manually if you have received a one in another way."} type={"Info"} version={"Secondary"} />
      <ModalInputField ref={inputRef} description={"License Code"} type={"Text"} backgroundType={"White"} onChange={(e) => setLicenceCode(licenceHandler(e))} value={licenceCode} onClick={() => setActiveInput("licenceCode")} placeholder={"XXXX-XXXX-XXXX-XXXX"} state={activeInput === "licenceCode" ? "Active" : "Empty"} onBlur={() => { setActiveInput("") }} onSelect={(e) => console.log(e)} />
      <ModalInputField type="Dropdown" onChange={(e) => { setProfile(profiles.filter((profile) => profile.ProfileName === e.target.value)[0]); }} placeholder={profile.ProfileName} description="Profile" value={profile?.ProfileName} options={profiles.map((profile) => {return { "text": profile?.ProfileName || "Unnamed Profile" }})} />
      {warning && warning.type === "invalidLicence" && <ModalParagraph color={"#EB3232"} text={"This license seems to be invalid"} type={"Info"} version={"Secondary"} />}
      {warning && warning.type === "usedLicence" && <ModalParagraph color={"#EB3232"} text={"This license is assigned to another account."} type={"Info"} version={"Secondary"} />}
      <ModalButtons cancelButton cancelButtonType={ButtonType.secondaryOutline} onCancelButtonClick={() => onCancel()} primaryButton primaryButtonText={"Add"} primaryButtonType={ButtonType.primary} onPrimaryButtonClick={() => { saveHandler() }} />
    </ModalContainer>
  )
}

export default ModalAddLicence