import styles from "./EchoHelloSettings.module.css"
import { TitleBanner, MenuContainer, MenuItem, ProfilePicker, BackgroundType, ItemText } from "@ctouch-europe-b-v/myctouch-component-library"
import EchoSettingGroup from "../echoSettingGroup/EchoSettingGroup";
import { useEffect, useState } from "react";
import AddProfileModal from "../addProfileModal/AddProfileModal";
import { EchoHelloProfileSettingService } from "../../../../services/EchoHelloProfileSettingService";
import ProfileMenuItem from "../profileMenuItem/ProfileMenuItem";
import { useSelector } from "react-redux";
import { useWebSocket } from '../../../../hooks/UseWebSocket';
import { EchoHelloProfileService } from "../../../../services/EchoHelloProfileService";

const EchoHelloSettings = ({ setActivePage, setIsCompanySidebarOpen, licenceData, profiles, setProfiles, selectedProfile, setSelectedProfile, refreshLicenceList }) => {

    const [settings, setSettings] = useState([])
    const [addProfileOpen, setAddProfileOpen] = useState(false)
    const { joinRoom, send } = useWebSocket()
    const company = useSelector((state) => state.companyWithLocationsAndDisplays.value)
    const [displays, setDisplays] = useState([])
    const selectedCompany = useSelector((state) => state.selectedCompany.value);
    const companyId = selectedCompany ? selectedCompany.Id : company.Id;
    const [warning, setWarning] = useState("");
    const user = useSelector((state) => state.user.value);
    const isReseller = user?.Roles?.some(item => item?.Name === "Reseller");
    const isAdmin = user?.Roles?.some(item => item?.Name === "Administrator");
    const isCTOUCH = user?.Roles?.some(item => item?.Name === "CTOUCH");
    const selectedUser = useSelector(state => state.selectedUser.value)

    useEffect(() => {
        if (selectedProfile) {
             EchoHelloProfileService.getProfile(selectedProfile.Id).then((result) => {
                setSettings(result)
            })
        }
    }, [selectedProfile])

    useEffect(() => {
        let items = [];
        company?.Locations.forEach(location => {
            location.Displays.forEach(display => {
                items.push(display);
            });
        });
        setDisplays(items);
    }, [company])

    const sendSetting = (setting) => {
        let updatedSetting = {
            id: setting.id,
            currentValue: setting.value,
            launcherSettingId: setting.settingId,
            profileId: selectedProfile.Id
        }
        licenceData.filter((EchoHelloClient) => {
            return EchoHelloClient.ProfileId === selectedProfile.Id
        }).forEach(EchoHelloClient => {
            joinRoom(EchoHelloClient.AssignedTo)
            send(EchoHelloClient.AssignedTo, setting.property, setting.value)
            if (setting.property === "logoSource" && setting.value === "CTOUCH") {
                send("logoUrl", "https://ctouchwebtools.blob.core.windows.net/echo/ctouchLogo.png")
            }
        });
        EchoHelloProfileSettingService.updateProfileSetting(updatedSetting)
    }

    const refreshProfiles = () => {
        EchoHelloProfileService.getAllProfiles(companyId).then((result) => {
            setProfiles(result);
        })
    }

    const sendAllSettings = async (profileId) => {
        //refresh licencedata before checking settings
        const updatedLicenceData = await refreshLicenceList();
        const updatedSettings = await EchoHelloProfileService.getProfile(profileId);

        if (updatedLicenceData != null || updatedLicenceData != undefined) {
            updatedLicenceData.filter((EchoHelloClient) => {
                return EchoHelloClient.ProfileId === profileId
            }).forEach(EchoHelloClient => {
                joinRoom(EchoHelloClient.AssignedTo)
                updatedSettings.forEach((setting) => {
                    send(EchoHelloClient.AssignedTo, setting.Setting.LauncherSettingName, setting.CurrentValue)
                    if (setting.Setting.LauncherSettingName === "logoSource" && setting.CurrentValue === "CTOUCH") {
                        send("logoUrl", "https://ctouchwebtools.blob.core.windows.net/echo/ctouchLogo.png")
                    }
                })
            });   
        }
    }

    return (
        <div className={styles.mainContainer}>
            <MenuContainer title="Settings" className={styles.submenu}>
                <MenuItem type="Title" title="Section" />
                <MenuItem type="Action" text="Profiles" isSelected iconName="sliders" onClick={() => { setActivePage("profiles") }} />
                <MenuItem type="Action" text="Licenses" iconName="file-certificate" onClick={() => { setActivePage("licenses") }} />
                {(!isAdmin && !isReseller && !isCTOUCH) || (!isAdmin && (isReseller || isCTOUCH) && selectedCompany === null) || (isAdmin && selectedUser !== null) ?
                    <></>
                    :
                    <>
                        <MenuItem type="Divider" />
                        <MenuItem type="Title" title="Profiles" />
                        {profiles.map((profile) => {
                            return <ProfileMenuItem onProfilePasted={(e) => { sendAllSettings(e) }} isIconDisabled={profile.ProfileName === "Default"} onProfilesAffected={async (id) => {await setSelectedProfile(profiles[0]); sendAllSettings(id); refreshProfiles()}} isSelected={selectedProfile === profile} profile={profile} type="Item" onClick={() => setSelectedProfile(profile)} profileType={"EchoHello"} warning={warning} setWarning={setWarning} />
                        })}
                        <MenuItem className={styles.spacer} type="Add" iconName="plus" text="Add Profile" onClick={() => setAddProfileOpen(true)} />
                    </>}
            </MenuContainer>
            {/* todo set correct banner background */}
            <TitleBanner title="CTOUCH Echo Hello" subtitle="Hybrid meetings made easy with the CTOUCH Echo interface." color="White" className={styles.banner}></TitleBanner>
            <div className={styles.content}>
                <ProfilePicker onClick={() => setIsCompanySidebarOpen(true)} backgroundType={BackgroundType.grey} buttonText="Change" className={styles.profilePicker} settingType={"organization"} settingTarget={selectedCompany?.Name ? selectedCompany?.Name : "yourself"} />

                {(!isAdmin && !isReseller && !isCTOUCH) || (!isAdmin && (isReseller || isCTOUCH) && selectedCompany === null) || (isAdmin && selectedUser !== null) ?
                    <ItemText className={styles.infoText} text={"The selected user/organisation has no access to this page. Please pick a different one, using the button above."} />
                    :
                    <>
                        <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "apps")} areTitlesLeft title={"Icons"} subtitle={"Setup what you can access from the home screen."} />
                        <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "components").slice(0, 3)} title={"Time and date"} />
                        <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "components").slice(3, 6)} title={"Logo"} />
                        <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "background")} title={"Background"} />
                        <EchoSettingGroup profile={selectedProfile} onSettingChanged={(e) => sendSetting(e)} settings={settings.filter((setting) => setting.Setting.SettingCategory.CategoryName === "behavior")} title={"Behavior"} subtitle={"Change various settings to alter Echo Hello behavior."} />
                    </>}
            </div>
            {addProfileOpen && <AddProfileModal warning={warning} onSave={(e) => {
                const profile = { profileName: e.name, companyId: companyId }
                EchoHelloProfileService.createProfile(profile).then((result) => {
                    if (result !== undefined) {
                        EchoHelloProfileSettingService.inheritProfileSettings(e.inherited, result.Id).then((result) => {
                            setAddProfileOpen(false);
                            refreshProfiles()
                        })
                    }
                })
                    .catch((error) => {
                        if (error.response && error.response.status === 400) {
                            if (error.response.data[0] === "This profile already exists") {
                                setWarning({ type: "profileAlreadyExists" })
                            }
                        }
                    });
            }} profiles={profiles} onCancel={() => { setAddProfileOpen(false); setWarning() }} />}
        </div>
    )
}

export default EchoHelloSettings;