import styles from './AccountManagement.module.css'
import { useRef, useState, useEffect, useContext } from 'react';
import ModalRemoveUser from '../../ui/organisms/modalRemoveUser/ModalRemoveUser';
import ModalInviteUser from '../../ui/organisms/modalInviteUser/ModalInviteUser';
import ModalSphereAccess from '../../ui/organisms/modalSphereAccess/ModalSphereAccess';
import AccountManagementTable from '../../ui/organisms/accountManagementTable/AccountManagementTable';
import Table from '../../ui/organisms/Table/Table';
import { TitleBanner, ButtonType, MenuContainer, MenuItem, ItemTitle, ItemText, Button, ProfilePicker, Filter, BackgroundType, MenuItemTitle, MenuItemDivider, MenuItemSimple, MenuItemAction, Snackbar } from "@ctouch-europe-b-v/myctouch-component-library";
import { MultiSelectContext, MultiSelectDispatchContext } from '../../../contexts/MultiSelectContext';
import useUpdateUser from '../../../hooks/useUpdateUser';
import MainMenu from '../../ui/molecules/mainMenu/MainMenu';
import { useSelector } from 'react-redux';
import Roles from '../../../enums/Roles';
import SettingsSubMenu from '../../ui/organisms/settingsSubMenu/SettingsSubMenu';
import useOnClickOutside from '../../../hooks/useOnClickOutside';


const AccountManagement = ({ }) => {
  const filterRef = useRef();
  const user = useSelector((state) => state.user.value);
  const userList = useSelector((state) => state.userList.value);
  const company = useSelector((state) => state.company.value);
  const selectedUser = useSelector(state => state.selectedUser.value)
  const selectedCompany = useSelector((state) => state.selectedCompany.value);
  const [activeModal, setActiveModal] = useState("");
  const [filterMenu, openFilterMenu] = useState(false);
  const [filterList, setFilterList] = useState();
  const [activeFilters, setActiveFilters] = useState({});
  const [activeFiltersAmount, setActiveFiltersAmount] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(userList);
  const [searchUsers, setSearchUsers] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [multiSelectActive, setMultiSelectActive] = useState(false);
  const [multiUsers, setMultiUsers] = useState([]);
  const [isCompanySidebarOpen, setIsCompanySidebarOpen] = useState(false)
  const [snack, setSnack] = useState(false)
  const multiSelected = useContext(MultiSelectContext);
  const multiSelectDispatch = useContext(MultiSelectDispatchContext);
  const updateUserHandler = useUpdateUser();
  const isReseller = user?.Roles?.some(item => item?.Name === "Reseller");
  const isAdmin = user?.Roles?.some(item => item?.Name === "Administrator");
  const isCTOUCH = user?.Roles?.some(item => item?.Name === "CTOUCH");
  const tableHeaderColumns = [{ centered: false, text: "Email", columnName: "Email", maxContent: true }, { centered: true, text: "Status", maxWidth: "200px" }, { centered: true, text: "Sphere Access", fixedWidth: "192px" }]
  const tableRowColumns = [
    { columnName: "Email", type: "Text", maxContent: true },
    { columnName: "UserStatus", type: "Icon", maxWidth: "200px", centered: true },
    {
      columnName: "SphereAccess",
      type: "Switch",
      disabledColumn: "UserStatus",
      disabledValue: ["Verified"],
      checkedColumn: "SphereAccess",
      compareColumn: "UserStatus",
      fixedWidth: "192px",
      changeFunction: (e, compareValue, object) => compareValue === "Verified" && giveAccessHandler(object)
    }]
  useOnClickOutside(filterRef, () => openFilterMenu(false))
  useEffect(() => {
    getFilterList();
  }, [userList])

  useEffect(() => {
    if (filterMenu) {
      const rect = filterRef.current.getBoundingClientRect();
      filterRef.current.style.maxHeight = `calc(95vh - ${rect.top}px - 64px)`;
      filterRef.current.style.right = `0`;
    }
  }, [filterMenu])

  // Remove all users from multi select when another organization is chosen
  useEffect(() => {
    multiSelectDispatch({ type: "Remove All" })
  }, [selectedCompany])

  useEffect(() => {
    const acceptedStatus = ["Pending", "Verified", "Denied"]
    if (multiSelected.filter(multiUser => acceptedStatus.includes(multiUser.status)).length !== 0) {
      setMultiSelectActive(true)
    } else {
      setMultiSelectActive(false)
    }
  }, [multiSelected])

  useEffect(() => {
    const filterBoolChecker = (trueVal, falseVal, object, key, filters) => {
      return (filters.includes(trueVal) && object[key] === 1) || (filters.includes(falseVal) && object[key] === 0)
    }
    setFilteredUsers(() => {
      let newUserList = JSON.parse(JSON.stringify(userList));
      const searchableColumns = ["FirstName", "LastName", "Email"]
      Object.entries(activeFilters).forEach(([filterField, filters]) => {
        if (filters.length !== 0) {
          newUserList = newUserList.filter((user) => {
            if (filterField === "SphereAccess") {
              return filterBoolChecker("Yes", "No", user, filterField, filters)
            }
            return filters.includes(user[filterField])
          })
        }
      })
      if (searchUsers && searchUsers !== "") {
        let newSearchList = [];
        newUserList.forEach((user) => {
          searchableColumns.forEach(field => {
            let userFound = newSearchList.filter(searchUser => Object.is(user, searchUser))
            if (user[field] !== null && user[field]?.toLowerCase().includes(searchUsers?.toLowerCase()) && userFound.length === 0) {
              newSearchList = [...newSearchList, user];
            }
          })
        })
        newUserList = newSearchList
      }
      return newUserList;
    })
  }, [activeFiltersAmount, searchUsers, userList])

  const convertBoolVal = (val, newVal) => {
    return typeof val === "number" && val === 1 ? newVal.trueVal : typeof val === "number" && val === 0 ? newVal.falseVal : val === null ? newVal.falseVal : val
  }

  const getFilterButtonText = () => {
    return `Filters${activeFiltersAmount > 0 ? ` [${activeFiltersAmount}]` : ''}`
  }
  const giveAccessHandler = (object) => {
    console.log("Setting Sphere access")
    setActiveModal({ name: "SphereAccess", info: { id: object.Email, isGive: !!!object.SphereAccess } });
  }
  const getSnackBarText = (type, val) => {
    switch (type) {
      case "InviteUser":
        return <>Invite sent to <strong>{val}</strong></>
      case "error":
        return <>Something went wrong, please try again</>
      default:
        return <>Something went wrong, please try again</>
    }
  }

  const getFilterList = () => {
    const filterableColumn = ["UserStatus", "SphereAccess"];
    let tempFilterList = {}
    userList?.forEach(user => {
      Object.keys(user).filter((key) => {
        if (filterableColumn.includes(key)) {
          return true;
        }
        return false;
      }).map((field) => {
        let newField = field === "SphereAccess" ? convertBoolVal(user[field], { trueVal: "Yes", falseVal: "No" }) : user[field] === null ? "Unknown" : user[field]
        const newItem = newField;
        if (tempFilterList[field] === undefined) {
          tempFilterList[field] = []
          tempFilterList[field].push(newItem);
        } else if (tempFilterList[field].filter(item => item === newField)?.length === 0) {
          tempFilterList[field].push(newItem);
        }
      })
    })
    setFilterList(tempFilterList)
  }

  const handleActiveFilters = (filterField, val) => {
    const value = Array.isArray(val) ? val[0] : val;
    let tempFilters = activeFilters;
    if (tempFilters[filterField] === undefined) {
      setActiveFiltersAmount(prev => prev = prev + 1)
      tempFilters[filterField] = [value];
    } else if (!tempFilters[filterField].includes(value)) {
      tempFilters[filterField] = [...tempFilters[filterField], value];
      setActiveFiltersAmount(prev => prev = prev + 1)
    } else {
      tempFilters[filterField] = tempFilters[filterField].filter(filter => filter !== value)
      setActiveFiltersAmount(prev => prev = prev - 1)
    }
    setActiveFilters(tempFilters)
  }

  const renderBannerName = () => {
    if (selectedUser !== null) {
      return selectedUser.FirstName === null && selectedUser.LastName === null ? selectedUser.Email : `${selectedUser.FirstName} ${selectedUser.LastName}`
    } else {
      return selectedCompany !== null ? selectedCompany.Name : company.Name
    }
  }

  const menuActionHandler = async (type) => {
    switch (type) {
      case "Verify":
        await Promise.all(
          multiSelected.map(async multiUser => {
            if (multiUser.status !== "Verified" && multiUser.status !== "Invited") {
              await updateUserHandler("UserStatus", "Verified", multiUser.id)
              multiSelectDispatch({ type: "Remove One", id: multiUser.id })
            }
          })
        )
        break;
      case "Deny":
        await Promise.all(
          multiSelected.map(async multiUser => {
            if (multiUser.status !== "Denied" && multiUser.status !== "Invited") {
              await updateUserHandler("UserStatus", "Denied", multiUser.id);
              multiSelectDispatch({ type: "Remove One", id: multiUser.id })
            }
          })
        )
        break;
      default:
        throw Error(`Unknown Menu Action: ${type}`)
    }
  }
  const icons = (type) => {
    switch (type) {
      case "Verified":
        return { name: "check", color: "#00BB00" }
      case "Denied":
        return { name: "xmark", color: "#EB3232" }
      case "Invited":
        return { name: "envelope", color: "#009FDA" }
      case "Pending":
        return { name: "clock", color: "#F65916" }
      default:
        return { name: "clock", color: "#009FDA" }
    }
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.mainContainer} >
        <MainMenu />
        <MenuContainer title="User" className={styles.submenu}>
          <MenuItem type={"Title"} title={"Verification"} />
          <MenuItem type={"Paragraph"} text={"Easily manage the users within your organization."} />
          <MenuItem type={"Paragraph"} text={"Verify or invite new users and manage their access to device management in Sphere."} />
          <MenuItem type={"Divider"} />
          <MenuItem type={"Title"} title={"Search"} />
          <MenuItem id={"userSearch"} type={"Search"} placeholder={"Users"} isSelected={searchUsers !== "" || searchActive} onClick={() => setSearchActive(true)} onChange={(e) => { setSearchUsers(e) }} onBlur={() => setSearchActive(false)} />
          <MenuItem type={"Divider"} />
          <MenuItem type={"Title"} title={"Actions"} />
          <MenuItem isDisabled={!multiSelectActive} type={"Action"} text={"Verify"} iconName={"check"} onClick={(() => menuActionHandler("Verify"))} />
          <MenuItem isDisabled={!multiSelectActive} type={"Action"} text={"Deny"} iconName={"xmark"} onClick={(() => menuActionHandler("Deny"))} />
        </MenuContainer>
        <TitleBanner title="Account Management" subtitle="Manage the user accounts within your organisation" color="White" className={styles.banner}></TitleBanner>
        <div className={styles.content}>
          <ProfilePicker className={styles.profilePicker} isDisabled={!isReseller && !isCTOUCH} buttonText={"Change"} onClick={() => setIsCompanySidebarOpen(true)} backgroundType={"Grey"} settingType={selectedUser !== null ? "user" : "organisation"} settingTarget={renderBannerName()} />
          {(!isAdmin && !isReseller && !isCTOUCH) || (!isAdmin && (isReseller || isCTOUCH) && selectedCompany === null) || (isAdmin && selectedUser !== null) ?
            <ItemText className={styles.infoText} text={"This page is only for organisation level management, Not user level. Please pick an organisation, or activate your own by clicking on the button above."} />
            :
            <>
              <div className={styles.contentHeaderWrapper}>

                <div className={styles.titleContainer}>
                  <ItemTitle title={`users(${userList.length})`} />
                  <ItemText className={styles.infoText} text={"View the users of your organisation"} />
                </div>
                <div className={styles.buttonContainer}>
                  <Button type={ButtonType.primary} text={"Invite User"} onClick={() => setActiveModal({ name: "InviteUser", info: { id: user.email } })} />
                  <div className={styles.filterWrapper}>
                    <Button type={ButtonType.secondary} text={getFilterButtonText()} onClick={() => openFilterMenu(prev => !prev)} />
                    {filterMenu &&
                      <Filter ref={filterRef} className={[styles.accountsFilter].filter(e => !!e).join(" ")} onClose={() => openFilterMenu(false)} backgroundType={"Grey"}  >
                        <MenuItem type={"Action"} filterMenu text={"Reset"} iconName={"arrow-rotate-left"} textClassName={[styles.reset, styles.text].filter(e => !!e).join(" ")} iconClassName={[styles.reset, styles.icon].filter(e => !!e).join(" ")} onClick={() => { setActiveFilters({}); setActiveFiltersAmount(0) }} />
                        <MenuItem type={"Divider"} filterMenu />
                        {Object.entries(filterList).map(([key, value], index) => {
                          return <MenuItem key={`${index}${key}`} withCheckbox filterMenu type={"Collection"} text={key.split(/(?=[A-Z])/).join(" ")} selected={activeFilters[key]} setSelected={(val) => handleActiveFilters(key, val)} list={value} />
                        })}
                      </Filter>
                    }
                  </div>
                </div>
              </div>
              <>
              <Table autoTable extraSelectColumn={[{ column: "status", value: "UserStatus" }]} multiSelectIdColumn={"Email"} rowColumns={tableRowColumns} headerColumns={tableHeaderColumns} actionColumn={tableHeaderColumns.length - 1} checkboxColumn={0} icons={icons} setMultiUsers={setMultiUsers} multiUsers={multiUsers} data={filteredUsers} setActiveModal={setActiveModal} activeFilters={activeFilters} currentUser={user} contextMenuType={"accountManagement"} />
              </>
            </>
          }
        </div>
        {activeModal.name === "SphereAccess" && <ModalSphereAccess userId={activeModal.info.id} isGive={activeModal.info.isGive} setActiveModal={() => setActiveModal("")} />}
        {activeModal.name === "InviteUser" && <ModalInviteUser setSnack={(object) => setSnack(object)} userId={activeModal.info.id} setActiveModal={() => setActiveModal("")} />}
        {activeModal.name === "RemoveUser" && <ModalRemoveUser userId={activeModal.info.id} setActiveModal={() => setActiveModal("")} />}
        {snack !== false && <Snackbar open={snack && snack.type === "InviteUser"} backgroundType={BackgroundType.white} text={getSnackBarText(snack.type, snack.val)} onClose={() => setSnack(false)} />}
        {snack !== false && <Snackbar open={snack && snack.type === "error"} backgroundType={BackgroundType.white} text={getSnackBarText(snack.type)} onClose={() => setSnack(false)} />}
      </div >
      {isCompanySidebarOpen &&
        <div>
          <SettingsSubMenu canAccessUsers={false} className={styles.settingSubMenu} user={user} />
          <div className={styles.overlay} onClick={() => setIsCompanySidebarOpen(false)} />
        </div>
      }
    </div>
  )
}


export default AccountManagement