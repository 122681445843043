import styles from './ModalAssignProfile.module.css';
import { ModalContainer, ModalInputField, ModalTitle, ModalParagraph, ModalButtons, ButtonType } from "@ctouch-europe-b-v/myctouch-component-library";
import { useSelector } from 'react-redux';
import { useRef, useState, useContext } from "react";
import { MultiSelectContext } from '../../../../contexts/MultiSelectContext';
import { MultiSelectDispatchContext } from '../../../../contexts/MultiSelectContext';
import { EchoHelloLicenceService } from '../../../../services/EchoHelloLicenceService';
import { EchoHelloProfileService } from "../../../../services/EchoHelloProfileService";
import { useWebSocket } from '../../../../hooks/UseWebSocket';


const ModalAssignProfile = ({ profile, onClose, licenceData, setLicenceData }) => {
  const licences = useContext(MultiSelectContext);
  const licenceDispatch = useContext(MultiSelectDispatchContext);
  const { joinRoom, send } = useWebSocket()
  const [loading, setLoading] = useState(false)
  const delay = ms => new Promise(r => setTimeout(r, ms))

  const assigningProfilesHandler = async () => {
    setLoading(true)

    for (const licence of licences) {
      // await delay(5000);
      let currentLicenceItem;
      let currentLicenceItemIndex;
      setLicenceData(prev => {
        const currentList = JSON.parse(JSON.stringify(prev))
        currentList.forEach((licenceListItem, index) => {
          if (licenceListItem.LicenceKey === licence.id) {
            currentLicenceItem = licenceListItem;
            currentLicenceItemIndex = index;
          }
        })
        currentLicenceItem.Profile = profile;
        currentLicenceItem.ProfileId = profile.Id
        currentList[currentLicenceItemIndex] = currentLicenceItem
        EchoHelloLicenceService.updateLicense(licence.id, profile.Id).then((licence) => {
          getAndSentSettings(licence);
        });
        return currentList
      })

      licenceDispatch({ type: "Remove One", id: licence.id })
    }

    setLoading(false);
    onClose();
  }

  const getAndSentSettings = async (licence) => {
    EchoHelloProfileService.getProfile(licence.ProfileId).then((settings) => {
      settings.forEach(setting => {
      joinRoom(licence.AssignedTo)
            send(licence.AssignedTo, setting.Setting.LauncherSettingName, setting.CurrentValue)
            if (setting.Setting.LauncherSettingName === "logoSource" && setting.CurrentValue === "CTOUCH") {
                send("logoUrl", "https://ctouchwebtools.blob.core.windows.net/echo/ctouchLogo.png")
            }
    })
    })
  }

  return (
    <ModalContainer hasOverlay onClick={() => onClose()}>
      <ModalTitle title={"Assign Profile"} />
      <ModalParagraph version={"Secondary"} text={<span>Are you sure you want to assign <strong>{profile.ProfileName}</strong> to the selected devices? You can always assign another profile later.</span>} />
      <ModalButtons cancelButton onCancelButtonClick={() => onClose()} primaryButton primaryButtonLoading={loading} primaryButtonText={"Assign"} onPrimaryButtonClick={() => { assigningProfilesHandler() }} />
    </ModalContainer>
  )
}

export default ModalAssignProfile