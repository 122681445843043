import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
    name: "profile",
    initialState: {
        profileId: null
    },
    reducers: {
        copy: (state, action) => {
            console.log(action.payload);
            state.profileId = action.payload
        }
    }
})



export const echoHelloProfileSlice = createSlice({
    name: "echoHelloProfile",
    initialState: {
        profileId: null
    },
    reducers: {
        copyEchoHello: (state, action) => {
            console.log(action.payload);
            state.profileId = action.payload
        }
    }
})

export const { copy } = profileSlice.actions
export const { copyEchoHello } = echoHelloProfileSlice.actions

export const profileReducer = profileSlice.reducer
export const echoHelloProfileReducer = echoHelloProfileSlice.reducer
export default profileSlice.reducer
