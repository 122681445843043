import { ModalContainer, ModalTitle, ModalInputField, ModalButtons, ModalParagraph } from "@ctouch-europe-b-v/myctouch-component-library"
import { useState } from "react";

const RenameProfileModal = ({ profile, onCancel, onSave, warning }) => {
    const [name, setName] = useState(profile.ProfileName);
    return (
        <ModalContainer hasOverlay>
            <ModalTitle title={"Rename Profile"} />
            <ModalInputField type="Text" placeholder="Name" description="Name" value={name} onChange={(e) => { setName(e.target.value) }} />
            {warning && warning.type === "profileAlreadyExists" && <ModalParagraph color={"#EB3232"} text={"This profile already exists."} type={"Info"} version={"Secondary"} />}
            <ModalButtons primaryButton cancelButton onCancelButtonClick={onCancel} primaryButtonText="Save" onPrimaryButtonClick={() => { profile.ProfileName = name; onSave(profile) }} />
        </ModalContainer>
    )
}

export default RenameProfileModal;